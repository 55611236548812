const Sellers= [
    {
      'name': 'David Smith',
      'botsNumber': 100,
      'rank': 1,
    },
    {
      'name': 'Emily Johnson',
      'botsNumber': 75,
      'rank': 2,
    },
    {
      'name': 'Michael Brown',
      'botsNumber': 50,
      'rank': 3,
    },
    {
      'name': 'Sarah Davis',
      'botsNumber': 30,
      'rank': 4,
    },
    {
      'name': 'Daniel Wilson',
      'botsNumber': 20,
      'rank': 5,
    }
  ]

export default Sellers;