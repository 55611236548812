import React, {useEffect, useState} from 'react';
import { useLocation, useParams} from "react-router-dom";
import axios from "axios";

import { IoCloseOutline, IoEllipsisHorizontalOutline } from 'react-icons/io5';
import { FiShare } from 'react-icons/fi';
import { FaHeart } from 'react-icons/fa';
import SharePopup from './SharePopup';

import { ChartComponent } from "../../components/Chart/ChartComponent";
import Footer from '../../components/Footer';
import Navbar from '../../components/Navbar';

import image2 from '../../assets/image2.png';

import profilePicture1 from '../../assets/profile_picture1.png';
import profilePicture2 from '../../assets/profile_picture2.png';
import profilePicture3 from '../../assets/profile_picture3.png';
import profilePicture4 from '../../assets/profile_picture4.png';
import API_URL from "../../utils/api";

function BotDetail({pair, profitFactor}) {
  const profilePictures = [
    profilePicture1,
    profilePicture2,
    profilePicture3,
    profilePicture4,
  ];


  const { state } = useLocation();
  const { botData } = state || {};
  const { uuid } = useParams();

  const randomIndexPP = Math.floor(Math.random() * profilePictures.length);
  const randomIndexPPHighest = Math.floor(
    Math.random() * profilePictures.length,
  );
  const randomProfilePicture = profilePictures[randomIndexPP];
  const randomProfilePictureHighest = profilePictures[randomIndexPPHighest];

  const [isSwapped, setIsSwapped] = useState(false);
  const [botDetails, setBotDetails] = useState(null);

  const handleImageClick = () => {
    setIsSwapped(!isSwapped);
  }

  // State variables for the width and height of the ChartComponent
  const [chartWidth, setChartWidth] = useState(window.innerWidth * 32 / 100);
  const [chartHeight, setChartHeight] = useState(window.innerHeight * 80 / 100);

  // Pop up for share
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const shareUrl = "https://aizy.com"; // L'URL à partager
  const title = "Discover Aizy !"; // Le titre pour le partage

  useEffect(() => {
    const handleResize = () => {
      setChartWidth(window.innerWidth * 32 / 100)
      setChartHeight(window.innerHeight * 80 / 100)
    }

    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [window.innerWidth, window.innerHeight])


  useEffect(() => {
    axios.get(`${API_URL}/v1/bot/perfs/${uuid}`, {
      headers: {
        Authorization: process.env.REACT_APP_API_KEY,
        'access-token': process.env.REACT_APP_JWT,
      },
    })
      .then((res) => {
        setBotDetails(res.data);
        console.log("botDetails: ", botDetails)
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <div>
      <Navbar />
      <div className="flex items-center justify-center mt-14">
        <div className="w-1/3 rounded-lg mr-12 relative">
          <div style={{ position: 'relative' }}>
            {isSwapped ? (
              <>
                <img src={image2} width={chartWidth} height={chartHeight} alt="" className="rounded-md object-cover object-center z-10"/>
                <div onClick={handleImageClick} className="absolute top-10 left-10 rounded-md object-cover object-center border-2 border-neutral-7 z-20">
                  <ChartComponent width={chartWidth * 25 / 100} height={chartHeight * 25 / 100} />
                </div>
              </>
            ) : (
              <>
                <div className="rounded-md object-cover object-center z-20">
                  <ChartComponent width={chartWidth} height={chartHeight} />
                </div>
                <div onClick={handleImageClick}>
                  <img src={image2} width={chartWidth * 25 / 100} height={chartHeight * 25 / 100} alt="" className="absolute top-10 left-10 rounded-md object-cover object-center border-2 border-neutral-7 z-10"/>
                </div>
              </>
            )}
          </div>
        </div>

        <div>
          <h2 className="text-[40px] font-bold mb-[16px]">{botData.name}</h2>
          <div className="w-[113px] h-[30px] border-2 border-green-500 rounded-md px-2 py-1 mb-[46px]">
            <p className="text-sm font-bold text-green-500">{botData.price}{botData.crypto}</p> 
          </div>
          <p className="text-[16px] text-gray-500 mb-[40px]">{botData.description}</p> 
          <div className="flex items-center my-2">
            <div className="w-[48px] h-[48px] bg-green-500 rounded-full mr-2">
              <img src={randomProfilePicture} alt="" className="w-full h-full rounded-full object-cover object-center"/>
            </div>
            <div className="flex flex-col">
              <p className="text-[14px] text-gray-500">Creator</p>
              <p className="text-[14px] dark:text-neutral-8 text-neutral-1">{botData.author}</p> 
            </div>
          </div>
          <div className="border-b border-gray-300 my-2 mt-[18px] mb-[16px]" />
          <div className="w-[384px] h-[184px] dark:bg-neutral-2 bg-white shadow-md rounded-lg mr-4 border border-neutral-4/10 p-[24px]">
            <div className="flex items-center my-2 mb-[20px]">
              <div className="w-[48px] h-[48px} bg-green-500 rounded-full mr-2">
                <img src={randomProfilePictureHighest} alt="" className="w-full h-full rounded-full object-cover object-center"/>
              </div>
              <div className="flex flex-col">
                <div className="flex items-center space-x-[8px]">
                  <p className="text-[16px] text-neutral-4">Highest bid by</p>
                  <p className="text-[16px] dark:text-neutral-8 text-neutral-1">Kohaku Tora</p>
                </div>
                <div className="flex items-center space-x-[12px]">
                  <p className="text-[24px] font-bold dark:text-neutral-8 text-neutral-1">1.46 ETH{pair}</p>
                  <p className="text-[24px] font-bold text-neutral-4">$2,764.89{profitFactor}</p>
                </div>
              </div>
            </div>
            <div className="flex justify-between mb-[16px]">
              <button type="button" className="w-[164px] h-[48px] flex items-center justify-center border-2 border-neutral-4 text-neutral-1 dark:text-neutral-8 font-bold px-4 py-2 rounded-full hover-animation">
                <a href="/marketplace">View all</a>
              </button>
              <button type="button" className="w-[164px] h-[48px] flex items-center justify-center bg-primary-1 text-neutral-8 font-bold px-4 py-2 rounded-full hover-animation">
                <a href="/payment">Accept</a>
              </button>
            </div>
          </div>
        </div>
        <div className="flex flex-col justify-between ml-4 z-20">
          <button type="button" className="w-8 h-8 bg-gray-300 dark:bg-gray-500 rounded-full  flex items-center justify-center mb-[16px] hover-animation">
            <a href="/marketplace">
              <IoCloseOutline className="text-gray-500 dark:text-gray-300" size={18} />
            </a>
          </button>
          <button
            type="button"
            className="w-8 h-8 bg-neutral-8 dark:bg-neutral-1 rounded-full border border-gray-300 flex items-center justify-center mb-[16px] hover-animation"
            onClick={() => setIsPopupOpen(true)}>
              <FiShare className="text-gray-500" size={16} />
          </button>
          <SharePopup
            isOpen={isPopupOpen}
            url={shareUrl}
            title={title}
            onClose={() => setIsPopupOpen(false)}
          />
          <button type="button" className="w-8 h-8 bg-neutral-8 dark:bg-neutral-1 rounded-full border border-gray-300 flex items-center justify-center mb-[16px] hover-animation">
            <FaHeart className="text-red-500" size={16} />
          </button>
          <button type="button" className="w-8 h-8 bg-neutral-8 dark:bg-neutral-1 rounded-full border border-gray-300 flex items-center justify-center hover-animation">
            <IoEllipsisHorizontalOutline className="text-gray-500" size={16} />
          </button>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default BotDetail;
