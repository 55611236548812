import React from 'react';
import Tags from '../../../components/Homepage/Tags';

function HotTags() {
  return (
    <div className='w-full md:h-[75vh] dark:bg-neutral-2 bg-neutral-7 min-h-[40vh] p-5 pt-20 flex flex-col gap-10 m-0'>
      <h1 className="headline-3 dark:text-neutral-8 text-neutral-2 font-bold px-20 text-center md:text-start">
        Hot Tags
      </h1>
      <div className="flex h-full flex-col md:flex-row md:gap-3 justify-around scroll-x md:overflow-x-auto gap-8 items-center md:items-start">
        <Tags/>
        <Tags/>
        <Tags/>
      </div>
    </div>
  );
}

export default HotTags;