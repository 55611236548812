import React from 'react';
import Navbar from "../../../components/Homepage/Navbar";
import Botcard from "../../../components/Homepage/BotCard";

function Discover() {
  return (
    <div className="w-full dark:bg-neutral-1 min-h-[40vh] p-5 pt-20 flex flex-col gap-10 m-0">
      <h1 className="headline-3 dark:text-neutral-8 text-neutral-2 font-bold px-20">Discover</h1>
      <Navbar className="ml-20"/>
      <div className="flex flex-row items-center m-auto flex-wrap gap-20 md:w-4/5 pt-20 h-screen overflow-auto">
        <Botcard/>
        <Botcard/>
        <Botcard/>
        <Botcard/>
        <Botcard/>
        <Botcard/>
        <Botcard/>
        <Botcard/>
      </div>
    </div>
  )
}

export default Discover;