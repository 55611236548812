import React from 'react';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';

import { AuthProvider } from './AuthContext';
import ProtectedRoute from './ProtectedRoute';
import BotDetail from './pages/BotDetail';
import FAQ from './pages/FAQ';
import Home from './pages/Home';
import Marketplace from './pages/Marketplace';
import Profile from './pages/Profile';
import EditProfile from './pages/Profile/Edit';
import SignIn from './pages/Authentification/SignIn';
import SignUp from './pages/Authentification/SignUp';
import Upload from './pages/Upload';
import UploadSucceeded from './pages/Upload/UploadSucceeded';
import NotFound from './pages/NotFound';
import Developer from './pages/Profile/Developer';
import PublicProfile from './pages/PublicProfile';
import LandingPage from "./pages/LandingPage";
import IsFeatures from './pages/IsFeatures';
import Inventory from "./pages/Inventory";

function App() {
  return (
    <AuthProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<SignUp />} />
          <Route path="/home" element={<Home />} />
          <Route path="/sign-in" element={<SignIn />} />
          <Route path="/sign-up" element={<SignUp />} />
          <Route path="/landing" element={<LandingPage />} />
          <Route
            path="/profile"
            element={<ProtectedRoute element={<Profile />} />}
          />
          <Route
            path="/profile/:username"
            element={<ProtectedRoute element={<PublicProfile />} />}
          />
          <Route
            path="/profile/edit"
            element={<ProtectedRoute element={<EditProfile />} />}
          />
          <Route
            path="/profile/developer"
            element={<ProtectedRoute element={<Developer />} />}
          />
          <Route
            path="/upload"
            element={<ProtectedRoute element={<Upload />} />}
          />
          <Route
            path="/upload/succeeded"
            element={<ProtectedRoute element={<UploadSucceeded />} />}
          />
          <Route
            path="/bot-detail/:uuid"
            element={<ProtectedRoute element={<BotDetail />} />}
          />
          <Route path="/faq" element={<ProtectedRoute element={<FAQ />} />} />
          <Route path="/inventory" element={<ProtectedRoute element={<Inventory />} />} />
          <Route
            path="/marketplace"
            element={<ProtectedRoute element={<Marketplace />} />}
          />
          <Route path="*" element={<IsRedirection />} />
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  );
}

function IsRedirection() {
  const location = useLocation();

  const paths = ['/inventory', '/cart', '/payment'];
  const inProgress = paths.includes(location.pathname);

  return inProgress ? <IsFeatures /> : <NotFound />;
}

export default App;
