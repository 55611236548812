import React from 'react';
import Navbar from '../../../components/Navbar';
import Footer from '../../../components/Footer';

import congratulations from '../../../assets/upload.png';

function UploadSucceeded() {
  return (
    <>
      <Navbar />
      <div className="flex flex-col items-center justify-center h-screen bg-neutral-8">
        {/* <div className="w-[800px] h-[300px] mb-[96px] flex items-center justify-center"> */}
        <div className="w-[800px] h-[300px] mt-[-60px] mb-[66px] flex items-center justify-center">
          <img src={congratulations} alt="" className="max-h-full max-w-full" />
        </div>
        <h1 className="text-[48px] font-bold text-neutral-2 mb-[-20px]">
          Congratulations
        </h1>
        <h1 className="text-[48px] font-bold text-neutral-2 mb-[8px]">
          your bot has been uploaded !
        </h1>
        <p className="text-[14px] text-gray-500 mt-2 mb-[20px]">
          Explore the marketplace again ?
        </p>
        <div className="mt-[24px]">
          <a
            href="/marketplace"
            className="bg-primary-1 text-neutral-8 font-bold py-3 px-6 rounded-full"
          >
            Return to the marketplace
          </a>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default UploadSucceeded;
