import React, { useState } from 'react';

function ScrollToTopButton() {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    const scrolled = document.documentElement.scrollTop;
    // Changer le 0 plus tard ça c'est un test
    if (scrolled > 0) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  window.addEventListener('scroll', toggleVisibility);

  return (
    <button type="button"
      className={`fixed bottom-8 right-8 bg-primary-1 text-neutral-8 px-4 py-2 rounded-full transition-opacity hover-animation ${
        isVisible ? 'opacity-100' : 'opacity-0'
      }`}
      onClick={scrollToTop}
    >
      Back to Top
    </button>
  );
}

export default ScrollToTopButton;
