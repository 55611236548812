import React, { useEffect, useState } from 'react';
import {FiBell, FiMenu, FiX} from 'react-icons/fi';
import { BsMoonStars, BsSun } from 'react-icons/bs';
import { RiSearchLine } from 'react-icons/ri';
import { Link  } from 'react-router-dom';
import logo from '../../assets/logo.svg';
import profilePicture from '../../assets/profile_picture.png';

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const [hasNotifications, setNotifications] = useState(false);
  const storedImageUrl = localStorage.getItem("userProfilePicture");

  // eslint-disable-next-line no-unused-vars
  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };
  // eslint-disable-next-line no-unused-vars
  const handleNotifications = () => {
    setNotifications(!hasNotifications);
  };

  const isDarkModePreferred = localStorage.theme === 'dark'
    || (!('theme' in localStorage)
      && window.matchMedia('(prefers-color-scheme: dark)').matches);

  const [isDarkMode, setIsDarkMode] = useState(isDarkModePreferred);

  // Update the theme class on document load to match the saved theme preference
  useEffect(() => {
    if (isDarkMode) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
    // Update the 'checked' attribute of the input element when the state changes
    document.getElementById('darkMode').checked = isDarkMode;
  }, [isDarkMode]);

  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
    localStorage.theme = !isDarkMode ? 'dark' : 'light';
  };

  return (
      <nav className="border-b-2 border-neutral-6 dark:border-neutral-3 w-screen">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between h-16">
            <div className="flex items-center space-x-8">
              <div className="md:flex items-center border-r-2 border-neutral-6 pr-4  hidden">
                <a
                    href="/landing"
                    className="text-[24px] font-bold text-neutral-2 dark:text-neutral-8"
                >
                  <img src={logo} alt="logo" className="w-12"/>
                </a>
              </div>
              <div className="md:hidden">
                <button  type="button" onClick={toggleNavbar} className="text-neutral-4">
                  {isOpen ? <FiX size={24}/> : <FiMenu size={24}/>}
                </button>
              </div>
              <div className={` items-center space-x-4 hidden md:flex`}>
                <a
                    href="/home"
                    className="text-neutral-4 hover:text-neutral-6 px-3 py-2 rounded-md text-[14px] font-medium"
                >
                  Home
                </a>
                <a
                    href="/marketplace"
                    className="text-neutral-4 hover:text-neutral-6 px-3 py-2 rounded-md text-[14px] font-medium"
                >
                  Marketplace
                </a>
                <a
                    href="/cart"
                    className="text-neutral-4 hover:text-neutral-6 px-3 py-2 rounded-md text-[14px] font-medium"
                >
                  Cart
                </a>
                <a
                    href="/inventory"
                    className="text-neutral-4 hover:text-neutral-6 px-3 py-2 rounded-md text-[14px] font-medium"
                >
                  Inventory
                </a>
              </div>
            </div>
            <div className="flex items-center space-x-4">
              <div className="md:flex hidden">
                <div className="relative flex-grow">
                  <input
                      type="text"
                      placeholder="Search"
                      className="dark:bg-neutral-1 text-neutral-4 border-neutral-6 rounded-lg px-2 py-2 focus:outline-none w-full pr-[50px]"
                  />
                  <button type="button" className="absolute top-[3px] right-[8px] rounded-full p-2 flex items-center">
                    <RiSearchLine className="text-neutral-4" size={20}/>
                  </button>
                </div>
              </div>
              <span className="relative inline-block">
              <FiBell className="text-neutral-4" size={24} strokeWidth={2}/>
                {hasNotifications && (
                    <span
                        className="rounded-full bg-primary-4 w-3 h-3 flex items-center justify-center absolute -top-0.5 -right-0.5"/>
                )}
            </span>
              <button type="button"
                      className="hidden md:block bg-primary-1 text-[14px] font-bold text-neutral-8 px-4 py-2 rounded-full transition-opacity hover-animation">
                <a href="/upload">Upload</a>
              </button>
              <Link to="/profile">
                <button type="button"
                        className="border-[2px] border-neutral-6 bg-neutral-8 dark:bg-neutral-1 text-[14px] font-bold text-neutral-2 px-4 py-2 rounded-full transition-opacity hover-animation">
                <span className="flex items-center">
                  <span className="rounded-full bg-primary-2 w-6 h-6 flex items-center justify-center mr-2">
                    <img
                        src={storedImageUrl || profilePicture}
                        alt=""
                        className="w-full h-full rounded-full object-cover object-center"
                    />
                  </span>
                  <p className="dark:text-neutral-8">
                    Profile
                  </p>
                </span>
                </button>
              </Link>
              {isDarkMode ?
                  <label htmlFor="darkMode" className="p-3 border-2 border-neutral-6 rounded-full hover-animation">
                    <input
                        type="checkbox"
                        id="darkMode"
                        className="hidden"
                        checked={isDarkMode}
                        onChange={toggleDarkMode}
                    />
                    <BsMoonStars/>
                  </label> :
                  <label htmlFor="darkMode" className="p-3 border-2 border-neutral-6 rounded-full hover-animation">
                    <input
                        type="checkbox"
                        id="darkMode"
                        className="hidden"
                        checked={isDarkMode}
                        onChange={toggleDarkMode}
                    />
                    <BsSun/>
                  </label>
              }
            </div>
          </div>
        </div>
        {isOpen && (
            <div className="md:hidden">
              <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3 flex flex-col items-center">
                <a
                    href="/home"
                    className="text-neutral-4 hover:text-neutral-6 block px-3 py-2 rounded-md text-base font-medium"
                >
                  Home
                </a>
                <a
                    href="/marketplace"
                    className="text-neutral-4 hover:text-neutral-6 block px-3 py-2 rounded-md text-base font-medium"
                >
                  Marketplace
                </a>
                <a
                    href="/cart"
                    className="text-neutral-4 hover:text-neutral-6 block px-3 py-2 rounded-md text-base font-medium"
                >
                  Cart
                </a>
                <a
                    href="/inventory"
                    className="text-neutral-4 hover:text-neutral-6 block px-3 py-2 rounded-md text-base font-medium"
                >
                  Inventory
                </a>
                <a
                    href="/upload"
                    className="text-neutral-4 hover:text-neutral-6 block px-3 py-2 rounded-md text-base font-medium"
                >
                  Upload
                </a>
                <div className="relative flex-grow flex items-center">
                  <input
                      type="text"
                      placeholder="Search"
                      className="dark:bg-neutral-1 text-neutral-4 border-neutral-6 rounded-lg px-2 py-2 focus:outline-none w-full pr-[50px]"
                  />
                  <button type="button" className="absolute top-[3px] right-[8px] rounded-full p-2 flex items-center">
                    <RiSearchLine className="text-neutral-4" size={20}/>
                  </button>
                  </div>
              </div>
            </div>
        )}
      </nav>
  );
}

export default Navbar;
