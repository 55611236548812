import { createChart, ColorType } from 'lightweight-charts';
import React, { useEffect, useRef } from 'react';
import * as LightweightCharts from "lightweight-charts";

export function ChartComponent(props) {
  const {
    colors: {
      backgroundColor = '#141416',
      lineColor = '#2962FF',
      textColor = 'white',
      areaTopColor = '#2962FF',
      areaBottomColor = 'rgba(41, 98, 255, 0.28)',
    } = {},
  } = props;

  const chartContainerRef = useRef();

  useEffect(() => {
    const chart = createChart(chartContainerRef.current, {
      layout: {
        background: { type: ColorType.Solid, color: backgroundColor },
        textColor,
        grid: {
          vertLines: {
            color: 'rgba(197, 203, 206, 0.5)',
          },
          horzLines: {
            color: 'rgba(197, 203, 206, 0.5)',
          },
        },
        crosshair: {
          mode: LightweightCharts.CrosshairMode.Normal,
        },
        rightPriceScale: {
          borderColor: 'rgba(197, 203, 206, 0.8)',
        },
        timeScale: {
          borderColor: 'rgba(197, 203, 206, 0.8)',
        },
      },
      width: props.width,
      height: props.height,
    });

    const handleResize = () => {
      chart.applyOptions({ width: props.width });
    };

    chart.timeScale().fitContent();
    const candlestickSeries = chart.addCandlestickSeries({ upColor: '#26a69a', downColor: '#ef5350', borderVisible: false, wickUpColor: '#26a69a', wickDownColor: '#ef5350' });

    const generateRealisticValue = (prevClose) => {
      const maxChange = 0.5;
      const change = (Math.random() - 0.5) * maxChange;
      return prevClose + change;
    };

    const generateRealisticData = (count) => {
      const now = Date.now() / 1000;
      const data = [];
      let prevClose = 10;
      for (let i = count - 1; i >= 0; i--) {
        const open = generateRealisticValue(prevClose);
        const close = generateRealisticValue(open);
        const high = Math.max(open, close) + Math.random() - 0.5;
        const low = Math.min(open, close) - Math.random() + 0.5;
        data.push({
          open,
          high,
          low,
          close,
          time: now - 84600 * i,
        });
        prevClose = close;
      }
      return data;
    };

    const data = generateRealisticData(100);

    candlestickSeries.setData(data);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);

      chart.remove();
    };
  }, [backgroundColor, lineColor, textColor, areaTopColor, areaBottomColor]);

  return (
    <div
      ref={chartContainerRef}
    />
  );
}