import React from 'react';
// import Logo from 'src/assets/logo.svg';

function Index() {
  return <div className='flex items-center justify-center gap-5 flex-1 flex-col flex-wrap py-6'>
    {/* <img src={Logo} alt='logo' className='w-1/4' /> */}
    <h1 className='font-bolder text-7xl'>Aizy</h1>
    <h2 className='font-bolder md:text-7xl text-5xl'>Trade easily.</h2>
    <p className='md:text-2xl text-xl w-1/2 text-center	'>With Aizy, rent trading bots to maximize your gains and reduce your chance of losses</p>
  </div>
}

export default Index;