import React, {useEffect, useState} from "react"
import {Link} from "react-router-dom";
import {BsArrowLeft} from "react-icons/bs"

import axios from "axios";

import Footer from "../../../components/Footer"
import Navbar from "../../../components/Navbar";
import profilePicture from "../../../assets/profile_picture.png";
import API_URL from "../../../utils/api";

function EditProfile() {
  const storedData = JSON.parse(sessionStorage.getItem("user"));
  const isDev = localStorage.getItem('isDev') === 'true';

  const [isAvatarChanged, setIsAvatarChanged] = useState(false);
  const [userProfilePicture, setUserProfilePicture] = useState(null);

  const headers = {
    'Authorization' : process.env.REACT_APP_API_KEY,
    'access-token' : storedData.token
  }

  useEffect(() => {
    // Check if the user has a profile picture
    axios.get(`${API_URL}/v1/user/profile`, { headers }).then((r) => {
      const link = r.data.avatar;
      if (link) {
        axios.get(`${API_URL}${link}`, { headers }).then(r => console.log(r));
      }
    });

    const storedImageUrl = localStorage.getItem("userProfilePicture");
    if (storedImageUrl) {
      setUserProfilePicture(storedImageUrl);
    }
  }, [isAvatarChanged, userProfilePicture]);


  const changePassword = () => {
    if (storedData && storedData.password) {
      const oldPassword = document.getElementById("old-password").value;
      if (oldPassword !== storedData.password) {
        alert("Old password is incorrect, try again.");
        return;
      }

      const newPassword = document.getElementById("new-password").value;
      const confirmPassword = document.getElementById("confirm-password").value;
      if (newPassword !== confirmPassword) {
        alert("The two passwords doesn't match, try again.");
        return;
      }

      storedData.password = newPassword;

      sessionStorage.setItem("user", JSON.stringify(storedData));

      document.getElementById("old-password").value = "";
      document.getElementById("new-password").value = "";
      document.getElementById("confirm-password").value = "";

      alert("Password successfully edited !");
      axios.patch(`${API_URL}/v1/user/changePassword`, {password: oldPassword, newpassword: newPassword}, {headers}).then(r => console.log(r))
    } else {
      alert("Can't find user, try to log in again.");
    }
  }

  const changeAvatar = (event) => {
    const avatarInput = event.target;
    const avatarFile = avatarInput.files[0];

    if (!avatarFile) {
      return;
    }

    const formData = new FormData();
    formData.append("avatar", avatarFile);

    axios.post(`${API_URL}/v1/user/avatar`, formData, {
      headers: {
        Authorization: process.env.REACT_APP_API_KEY,
        "access-token": storedData.token,
        "Content-Type": "multipart/form-data",
      },
    }).then((response) => {
      console.log(response);

      const newImageUrl = URL.createObjectURL(avatarFile);
      setUserProfilePicture(newImageUrl);
      setIsAvatarChanged(true);

      localStorage.setItem("userProfilePicture", newImageUrl);
    }).catch((error) => {
      console.error(error);
      alert("An error occurred while updating your avatar.");
    });
  };


  const saveChanges = () => {

  }


  const renderTopBar = () => (
      <div className="flex justify-between items-center w-4/5 mx-auto">
        <Link to="../profile" className="btn-primary-outlined rounded-full flex gap-3 hover-animation items-center dark:text-neutral-8">
          <BsArrowLeft />
          <input type="submit" value="Back to profile" className="cursor-pointer" />
        </Link>
        <div className="flex gap-6 items-center">
          <Link to="../profile" className="font-semibold text-neutral-4">Profile</Link>
          <p className="font-semibold text-neutral-4">{'>'}</p>
          <p className="font-semibold text-neutrals-6">Edit Profile</p>
        </div>
      </div>
    )

  console.log(userProfilePicture)

  const renderEditProfile = () => (
      <div className="w-3/5 mx-auto mt-20">
        {/* Title */}
        <div>
          <h1 className="title mb-2">Edit profile</h1>
          <h4 className="text-neutral-4 dark:text-neutral-6">You can set preferred display name, create <span className="text-neutral-2 dark:text-neutral-8 font-bold">your profile URL</span> and manage other personal settings.</h4>
        </div>
        {/* Form */}
        <div className="flex justify-between mt-12 gap-24">
          {/* Left side */}
          <div className="w-1/2">
            <div className="w-full flex gap-8 align-top">

              <img id="avatarImage" src={userProfilePicture || profilePicture} alt="User Profile" className="w-36 h-36 rounded-full object-cover object-center" />
              <div className="mt-2">
                <h2 className="subtitle">Profile picture</h2>
                <h4 className="mb-6">We recommend an image of at least 400x400.</h4>
                <div className="flex gap-4">
                  <input type="file" className="btn-primary-outlined absolute hidden cursor-auto dark:bg-neutral-1 dark:text-neutral-8 overflow-hidden" id="fileInputAvatar" accept="image/*" onChange={changeAvatar} />
                  <label htmlFor="fileInputAvatar" className="btn-primary-outlined cursor-pointer dark:bg-neutral-1 dark:text-neutral-8 overflow-hidden">
                    Upload
                  </label>
                  {isDev ? (
                    <input type="submit" name="Developer mode" value="Developer mode" className="btn-primary-outlined h-fit dark:text-primary-4 border-primary-4 hover-animation" />
                  ) : (
                    <input type="submit" name="Developer mode" value="Developer mode" className="btn-primary-outlined h-fit dark:text-primary-3 border-primary-3 hover-animation" />
                  )}
                </div>
              </div>
            </div>
            <h2 className="subtitle mt-12">Change your password</h2>
            <div className="flex flex-col justify-between gap-4 mt-8">
              <input id="old-password" type="text" className="btn-primary-outlined cursor-auto dark:bg-neutral-1 dark:text-neutral-8" placeholder="Old password" />
              <div className="flex gap-2 justify-between">
                <input id="new-password" type="text" className="w-3/5 btn-primary-outlined cursor-auto dark:bg-neutral-1 dark:text-neutral-8" placeholder="New password" />
                <input id="confirm-password" type="text" className="w-3/5 btn-primary-outlined cursor-auto dark:bg-neutral-1 dark:text-neutral-8" placeholder="Confirm password" />
              </div>
              <input type="submit" value="Confirm" className="btn-primary bg-primary-4 hover-animation" onClick={changePassword} />
            </div>
          </div>
          {/* Right side */}
          <div className="w-1/2 flex flex-col gap-8 ml-4">
            <h2 className="subtitle mt-2 mb-6">Account info</h2>

            <div className="flex flex-col gap-2">
              <label className="text-neutral-5 font-bold text-xs" htmlFor="name">DISPLAYED NAME</label>
              <input id="name" type="text" className="btn-primary-outlined cursor-auto dark:bg-neutral-1 dark:text-neutral-8" placeholder="Your displayed name" />
            </div>

            <div className="flex flex-col gap-2">
              <label className="text-neutral-5 font-bold text-xs" htmlFor="url">CUSTOM URL</label>
              <input id="url" type="text" className="btn-primary-outlined cursor-auto dark:bg-neutral-1 dark:text-neutral-8" placeholder="Your custom URL" />
            </div>

            <div className="flex flex-col gap-2">
              <label className="text-neutral-5 font-bold text-xs" htmlFor="bio">BIO</label>
              <textarea id="bio" rows={5} className="btn-primary-outlined cursor-auto dark:bg-neutral-1 dark:text-neutral-8" placeholder="About yourself in few words" />
            </div>

            <h2 className="subtitle mt-2 mb-6">Social</h2>

            <div className="flex flex-col gap-2">
              <label className="text-neutral-5 font-bold text-xs" htmlFor="portfolio">PORTFOLIO OR WEBSITE</label>
              <input id="portfolio" type="text" className="btn-primary-outlined cursor-auto dark:bg-neutral-1 dark:text-neutral-8" placeholder="Your custom URL" />
            </div>

            <div className="flex flex-col gap-2">
              <label className="text-neutral-5 font-bold text-xs" htmlFor="twitter">TWITTER</label>
              <input id="twitter" type="text" className="btn-primary-outlined cursor-auto dark:bg-neutral-1 dark:text-neutral-8" placeholder="@twitter username" />
            </div>

            <div className="flex flex-col gap-2">
              <label className="text-neutral-5 font-bold text-xs" htmlFor="instagram">INSTAGRAM</label>
              <input id="instagram" type="text" className="btn-primary-outlined cursor-auto dark:bg-neutral-1 dark:text-neutral-8" placeholder="@instagram username" />
            </div>

            <div className="flex gap-4">
              <input type="submit" value="Save changes" className="btn-primary py-2 w-1/3 bg-primary-1 hover-animation" onClick={saveChanges} />
              <input type="submit" value="Clear all" className="btn-primary py-2 w-1/3 bg-primary-3 hover-animation" />
            </div>
          </div>
        </div>
      </div>
    )

  return (
    <>
      <Navbar />
      <div>
        <div className="w-full border-b border-neutral-6 dark:border-neutral-3 py-8">
          {renderTopBar()}
        </div>
        {renderEditProfile()}
      </div>
      <Footer />
    </>
  )
}

export default EditProfile