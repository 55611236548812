import React from 'react';

import {PiTrophy} from 'react-icons/pi';
import {BsArrowUpRight, BsPlusSquare} from 'react-icons/bs';
import image1 from '../../../assets/image1.png';

function Seller({name = 'Curtis', botsNumber = 5, image = image1, rank = 1, color = 'bg-blue-500'}) {
  return (
    <div className="w-[12vw] min-w-[150px] min-h-[150px] h-3/5 hover-animation shadow-2xl flex flex-col items-center justify-around gap-3 rounded-xl dark:bg-neutral-1 bg-neutral-8">
      <div className="dark:border-neutral-3 border-b border-neutral-6 py-6 w-[80%] flex flew-row justify-between items-center">
        <div className={`${color} rounded-full w-[35%] flex flex-row items-center justify-evenly text-white p-1`}>
          <PiTrophy className="text-xs"/>
          <p className="text-xs">#{rank}</p>
        </div>
        <div className="flex gap-2 items-baseline">
          <BsPlusSquare className="dark:text-neutral-4 text-neutral-5 text-xl font-bold"/>
          <BsArrowUpRight className="dark:text-neutral-4 text-neutral-5 text-xl font-bold"/>
        </div>
      </div>
      <div className="w-2/5">
        <img className="w-full h-full rounded-full object-cover object-center" src={image} alt=""/>
      </div>
      <div className="pb-2 w-full flex flex-col justify-center items-center">
        <h2 className="text-l font-semibold">{name}</h2>
        <p className="text-xs text-neutral-4 "><strong className="dark:text-neutral-8 text-neutral-3">{botsNumber}</strong> Bots approved</p>
      </div>
    </div>
  );
}

export default Seller;