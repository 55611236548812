import React, { useContext } from 'react';
import { AuthContext } from './AuthContext';

function ProtectedRoute({ element }) {
  const { user } = useContext(AuthContext);

  if (user === undefined) {
    return <h1>Loading</h1>;
  } if (user === null) {
    window.location.href = '/sign-in';
  } else {
    return <>{element}</>;
  }
}

export default ProtectedRoute;
