import React from 'react';
import {BsChevronDown} from 'react-icons/bs';
import data from '../data';
import Sellers from '../../../components/Homepage/Seller';
import profilePicture0 from '../../../assets/profile_picture.png';
import profilePicture1 from '../../../assets/profile_picture4.png';
import profilePicture2 from '../../../assets/profile_picture1.png';
import profilePicture3 from '../../../assets/profile_picture2.png';
import profilePicture4 from '../../../assets/profile_picture3.png';

function PopularSellers () {
  const profilePicture = [
    profilePicture0,
    profilePicture1,
    profilePicture2,
    profilePicture3,
    profilePicture4,
  ];
  const colors = [
    'bg-blue-500',
    'bg-purple-500',
    'bg-green-500',
    'bg-yellow-500',
    'bg-red-500',
  ];
  return (
  <div className="w-full m-0 md:h-100 bg-neutral-7 dark:bg-neutral-2 px-20 pt-20 ">
    <h2 className="body-1 text-neutral-4 font-bold text-start md:text-center">Popular</h2>
    <h1 className="headline-3 dark:text-neutral-8 text-neutral-2 font-bold flex items-center gap-5 text-start md:text-center">Sellers <BsChevronDown/></h1>
    <div className="w-full p-0 height-full md:h-[50vh] flex flex-col md:flex-row justify-around gap-10 items-center scroll-x overflow-auto flex-wrap">
      {data.map((item, id) => (
        <Sellers key={item.id} {...item} image={profilePicture[id]} color={colors[id]} />
      ))}
    </div>
  </div>
  )
}

export default PopularSellers;