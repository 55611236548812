import React from 'react';
import Footer from '../../components/Footer';
import Navbar from '../../components/Navbar';

import notFound from '../../assets/not-found-figure.png';

function IsFeatures() {
  return (
    <>
      <Navbar />
      <div className="flex flex-col items-center justify-center h-screen bg-neutral-8 dark:bg-neutral-1">
        <div className="w-[1120px] h-[397px] mt-[60px] mb-[66px] flex items-center justify-center">
          <img src={notFound} alt="" className="max-h-full max-w-full" />
        </div>
        <h1 className="text-[48px] font-bold text-center w-2/5 text-neutral-2 dark:text-neutral-7 mb-[-20px] pb-8">
          Sorry, this feature has not yet been implemented.
        </h1>
        <button type="button" className="bg-primary-1 text-neutral-8 font-bold py-3 px-6 rounded-full hover-animation mt-[24px]">
          <a href="/home">Return to home</a>
        </button>
      </div>
      <Footer />
    </>
  );
}

export default IsFeatures;
