import React, {useEffect} from 'react';
import axios from "axios";

import Footer from '../../components/Footer';
import Navbar from '../../components/Navbar';
import PopularSellers from './Views/PopularSellers';
import MainPage from './Views/MainPage';
import HotBots from './Views/HotBots';
import HotTags from './Views/HotTags';
import Discover from './Views/Discover';
import API_URL from "../../utils/api";

function Home() {
  useEffect(() => {
    axios.get(`${API_URL}/v1/user/profile`, {
      headers: {
        Authorization: process.env.REACT_APP_API_KEY,
        'access-token': sessionStorage.getItem("userToken"),
      },
    }).then((response) => {
      const link = response.data.avatar;
      if (link) {
        axios.get(`${API_URL}${link}`, {
          responseType: 'arraybuffer',  // Specify the response type as 'arraybuffer'
          headers: {
            Authorization: process.env.REACT_APP_API_KEY,
            'access-token': sessionStorage.getItem("userToken"),
          },
        }).then((imageResponse) => {
          const base64Image = btoa(
            new Uint8Array(imageResponse.data).reduce(
              (data, byte) => data + String.fromCharCode(byte),
              ''
            )
          );
          const imageDataURL = `data:${imageResponse.headers['content-type'].toLowerCase()};base64,${base64Image}`;
          localStorage.setItem('userProfilePicture', imageDataURL);

        }).catch((error) => {
          console.error("Error fetching image:", error);
        });
      }
    }).catch((error) => {
      console.error("Error fetching profile:", error);
    });
  }, []);

  return (
    <div className="p-0 m-0 w-screen sm:w-full h-full">
      <Navbar/>
      <MainPage/>
      <PopularSellers/>
      <HotBots/>
      <HotTags/>
      <Discover/>
      <Footer/>
    </div>
  );
}

export default Home;
