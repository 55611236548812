import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {BiCircle, BiUpload, BiDotsHorizontalRounded, BiImageAlt} from 'react-icons/bi';
import { TbWorldWww, TbPencil } from 'react-icons/tb';
import { FiTwitter, FiInstagram, FiFacebook } from 'react-icons/fi';

import {Link, useParams} from 'react-router-dom';
import Footer from '../../components/Footer';
import Navbar from '../../components/Navbar';
import BotCard from "../../components/BotCard";

import banner from '../../assets/banner.png';
import API_URL from "../../utils/api";

function Profile() {
  const [onInputClick, setOnInputClick] = useState('OnSale');
  const storedProfilePicture = localStorage.getItem('userProfilePicture');
  const { username: routeUsername } = useParams();

  const [data, setData] = useState([]);

  useEffect(() => {
    axios.get(`${API_URL}/v1/user/profile/${routeUsername}`, {
      headers: {
      Authorization: process.env.REACT_APP_API_KEY,
        'access-token': sessionStorage.getItem("userToken"),
    },
  })
    .then((res) => {
      setData(res.data.data)
      console.log(res.data)
    })
    .catch((error) => {
      console.error(error);
    });
  }, [routeUsername])

  // region Handlers
  const handleItemClick = (itemName) => {
    setOnInputClick(itemName);
  };
  // endregion

  // region Renders
  const renderProfileInfos = () => (
    <div className="w-1/5 flex items-center flex-col justify-center p-8 bg-neutral-8 dark:bg-neutral-2 shadow-lg rounded-2xl">
      <div className="bg-blue-600 rounded-full w-36 h-36">
        <img
          src={storedProfilePicture}
          alt=""
          className="w-full h-full rounded-full object-cover object-center"
        />
      </div>
      <h2 className="subtitle mt-8 mb-1">{data.username}</h2>
      {data.dev ? (
        <h3 className="text-lg font-medium flex justify-around items-center gap-2">
          Developer mode
          {' '}
          <BiCircle className="text-primary-4 mt-0.5" />
        </h3>
      ) : (
        <h3 className="text-lg font-medium flex justify-around items-center gap-2">
          Developer mode
          {' '}
          <BiCircle className="text-primary-3 mt-0.5" />
        </h3>
      )}
      <h4 className="text-center font-normal text-neutral-4 mt-4">
        {data.description}
      </h4>
      <h4 className="mt-4 text-md font-medium flex items-center justify-around gap-2">
        <TbWorldWww className="text-neutral-4 mt-0.5" />
        https://curtis.me
      </h4>
      <div className="mt-12 w-full gap-2 flex items-center justify-center">
        <input
          type="submit"
          className="px-4 py-2 cursor-pointer bg-primary-1 hover-animation font-semibold text-neutral-7 rounded-3xl"
          value="Follow"
        />
        <button type="button" className="btn-primary-outlined px-3 rounded-full hover-animation dark:text-neutral-8">
          <BiUpload />
        </button>
        <button type="button" className="btn-primary-outlined px-3 rounded-full hover-animation dark:text-neutral-8">
          <BiDotsHorizontalRounded />
        </button>
      </div>
      <div className="mt-12 pb-12 border-b border-neutral-6 w-full flex items-center justify-center gap-4">
        <FiTwitter size={20} className="text-neutral-4 hover-animation" />
        <FiInstagram size={20} className="text-neutral-4 hover-animation" />
        <FiFacebook size={20} className="text-neutral-4 hover-animation" />
      </div>
      <p className="mt-4 text-center text-neutral-4">
        Member since Apr 15, 2023
      </p>
    </div>
  );

  const renderFilters = () => (
    <div className="w-1/2 h-8 mb-8 gap-4 flex justify-between">
      <input
        type="submit"
        name="OnSale"
        value="On Sale"
        className={
          onInputClick !== 'OnSale'
            ? 'neutral-4 font-semibold hover-animation'
            : 'btn-primary hover-animation'
        }
        onClick={() => handleItemClick('OnSale')}
      />
      <input
        type="submit"
        name="Collectibles"
        value="Collectibles"
        className={
          onInputClick !== 'Collectibles'
            ? 'neutral-4 font-semibold hover-animation'
            : 'btn-primary hover-animation'
        }
        onClick={() => handleItemClick('Collectibles')}
      />
      <input
        type="submit"
        name="Created"
        value="Created"
        className={
          onInputClick !== 'Created'
            ? 'neutral-4 font-semibold hover-animation'
            : 'btn-primary hover-animation'
        }
        onClick={() => handleItemClick('Created')}
      />
      <input
        type="submit"
        name="Likes"
        value="Likes"
        className={
          onInputClick !== 'Likes'
            ? 'neutral-4 font-semibold hover-animation'
            : 'btn-primary hover-animation'
        }
        onClick={() => handleItemClick('Likes')}
      />
      <input
        type="submit"
        name="Following"
        value="Following"
        className={
          onInputClick !== 'Following'
            ? 'neutral-4 font-semibold hover-animation'
            : 'btn-primary hover-animation'
        }
        onClick={() => handleItemClick('Following')}
      />
      <input
        type="submit"
        name="Followers"
        value="Followers"
        className={
          onInputClick !== 'Followers'
            ? 'neutral-4 font-semibold hover-animation'
            : 'btn-primary hover-animation'
        }
        onClick={() => handleItemClick('Followers')}
      />
    </div>
  );
  // endregion

  return (
    <>
      <Navbar />
      <div className="h-screen overflow-hidden flex flex-col items-center">
        <div className="w-full h-1/3 bg-gradient-to-r from-cyan-500 to-primary-1">
          <img
            src={banner}
            alt=""
            className="w-full h-full object-cover object-center"
          />
        </div>
        <div className="-mt-40 flex w-4/5 h-fit justify-between">
          {renderProfileInfos()}
          <div className="w-full p-16 mt-40">
            {renderFilters()}
            <div className="w-full flex gap-8 justify-between">
              {data?.bots?.map((bot) => (
                <BotCard
                  id={bot._id}
                  uuid={bot.uuid}
                  name={bot.name}
                  price={bot.price}
                  author={bot.author}
                  avatar={bot.avatar}
                />))}
            </div>
          </div>
          <div className="mt-20 flex gap-4">
            <div className="btn-primary-outlined h-fit bg-transparent hover-animation text-neutral-8 flex gap-2">
              <input
                type="submit"
                className="bg-transparent cursor-pointer"
                value="Edit cover photo"
              />
              <BiImageAlt size={20} className="mt-0.5" />
            </div>
            <Link
              to="/profile/edit"
              className="btn-primary-outlined h-fit bg-transparent hover-animation text-neutral-8 flex gap-2 "
            >
              <input
                type="submit"
                className="bg-transparent cursor-pointer"
                value="Edit profile"
              />
              <TbPencil size={20} className="mt-0.5" />
            </Link>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Profile;
