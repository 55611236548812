import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaUserCheck } from "react-icons/fa";

import image1 from '../../assets/image1.png';
import image2 from '../../assets/image2.png';
import image3 from '../../assets/image3.png';
import image4 from '../../assets/image4.png';
import image5 from '../../assets/image5.png';

function BotCard({name, price, currency, author, uuid, onClick, followers}) {
  const [randomImage, setRandomImage] = useState(null);

  useEffect(() => {
    const images = [image1, image2, image3, image4, image5];
    const randomIndex = Math.floor(Math.random() * images.length);
    const randomImage = images[randomIndex];
    setRandomImage(randomImage);
  }, []);

  const handleCardClick = () => {
    onClick(uuid);
  }

  return (
    <div onClick={handleCardClick} className="relative flex flex-col p-[12px] w-[256px] h-[450px] bg-neutral-8 dark:bg-neutral-2 rounded-md shadow-md">
      <Link to={`/bot-detail/${uuid}`} style={{ textDecoration: 'none', color: 'inherit' }}>
        <div style={{ cursor: 'pointer' }}>
          <div className="w-full h-[300px] bg-pink-200 rounded-md">
            <img
              src={randomImage}
              alt=""
              className="w-full h-full rounded-md object-cover object-center"
            />
          </div>
          <div className="flex flex-row items-center justify-between mt-4">
            <h2 className="text-xl font-medium mr-2">{name}</h2>
            <div className="border-2 border-green-600 rounded-md px-2 py-1">
              <p className="text-sm font-bold text-green-600">
                {price}
                {currency}
                /MONTHS
              </p>
            </div>
          </div>
          <div className="flex flex-row items-center justify-between mt-3">
            <div className="w-[22px] h-[22px] bg-purple-500 rounded-full">
              <img
                src={randomImage}
                alt=""
                className="w-full h-full rounded-full object-cover object-center"
              />
            </div>
            <h2 className="text-xl font-medium ml-2">{author}</h2>
          </div>
          <div className="flex items-end space-x-2 justify-end">
            <h2 className="text-xs font-medium ml-2">{followers}</h2>
            <FaUserCheck />
          </div>
        </div>
      </Link>
    </div>
  );
}

export default BotCard;
