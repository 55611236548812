import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import { useCookies } from 'react-cookie';
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';

function Inventory() {
  // const [cookie] = useCookies(['token']);
  const [list] = useState([]);

  // useEffect(() => {
  //   axios
  //     .get(`http://localhost:8081/user/rented_bots`, {
  //       headers: { Authorization: 'Bearer ' + cookie.token },
  //     })
  //     .then((res) => {
  //       setList(res.data);
  //     })
  //     .catch((err) => {
  //       // alert(err.data);
  //       console.log(err);
  //     });
  // }, [cookie, setList]);

  useEffect(() => {
    window.localStorage.removeItem('bot');
  }, []);

  return (
    <>
      <Navbar />
      <div className="container mx-auto flex justify-center">
        <h1 className="text-3xl font-bold mb-6 mt-[10vh] ">Your bots</h1>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {list.map((bot) => (
            <div
              key={bot.id}
              className="bg-neutral-8 rounded-lg shadow-md p-4 flex flex-col justify-between"
            >
              <img
                src={bot.image || ''}
                alt={bot.name}
                className="w-32 h-32 mx-auto mb-4 object-contain"
              />
              <div className="text-center">
                <h2 className="text-lg font-bold mb-2">{bot.name}</h2>
                <p className="text-gray-600">{bot.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Inventory;
