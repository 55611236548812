import React from 'react';
import Hotbot from '../../../../assets/hotbots1.png'
import Sellers from '../../../../assets/sellers.png'



// eslint-disable-next-line
function Content(props) {
  return (
    <div className="flex items-center justify-center gap-y-12 pb-5 flex-col">
      <h1 className="text-3xl">Contents</h1>
      <div className="flex items-center justify-center flex-col md:flex-row gap-24">
        <div className="flex flex-col gap-6 items-center justify-center">
          <div className="md:max-w-[25vw] text-center w-96 px-4">Be aware of the latest trend through the HotBots list</div>
          <div className="md:w-[45vw] w-[80vw]">
            <img className="" src={Hotbot} alt="Hot bots"/>
          </div>
        </div>
        <div className="flex flex-col gap-6 items-center justify-center">
          <div className="md:max-w-[25vw] w-96 px-4 text-center">Get in touch with the best current developers using the Sellers list</div>
          <div className="md:w-[45vw] w-[80vw]">
            <img className="" src={Sellers} alt="Sellers"/>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Content;