import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { AuthContext } from '../../../AuthContext';
import API_URL from "../../../utils/api";

function SignUp() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');

  const { login } = useContext(AuthContext);

  const handleSignUp = () => {
    const user = {
      username,
      email,
      password,
    };

    const headers = {
      Authorization: process.env.REACT_APP_API_KEY,
    };

    axios
      .post(
        `${API_URL}/v1/register`,
        { username, email, password },
        { headers },
      )
      .then((res) => {
        // eslint-disable-next-line no-console
        console.log(res);
      });
    sessionStorage.setItem('user', JSON.stringify(user));

    login(user);
    window.location.href = '/marketplace';
  };

  return (
    <div className="flex flex-col items-center justify-center h-full w-full">
      <div className="flex justify-center items-center flex-col w-screen h-screen gap-8">
        <h1 className="title">Sign up</h1>
        <div className="buttons mt-16">
          <div className="username-btn px-3">
            <label
              htmlFor="username"
              className="block font-medium leading-6 text-xl text-gray-900"
            >
              Username
              <div className="relative mt-2 rounded-md shadow-sm">
                <input
                  type="text"
                  placeholder="Username"
                  name="username"
                  value={username}
                  className="block w-80 sm:w-96 rounded-xl border-0 py-2 pl-4 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  onChange={(e) => setUsername(e.target.value)}
                  required
                />
              </div>
            </label>

          </div>
          <div className="email-btn mt-4 px-3">
            <label
              htmlFor="email"
              className="block font-medium leading-6 text-xl text-gray-900"
            >
              Email
              <div className="relative mt-2 rounded-md shadow-sm">
                <input
                  type="email"
                  placeholder="example@live.com"
                  name="email"
                  className="block w-80 sm:w-96 rounded-xl border-0 py-2 pl-4 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
            </label>
          </div>
          <div className="password-btn mt-4 px-3">
            <label
              htmlFor="password"
              className="block font-medium leading-6 text-xl text-gray-900"
            >
              Password
              <div className="relative mt-2 rounded-md shadow-sm">
                <input
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="block w-80 sm:w-96 rounded-xl border-0 py-2 pl-4 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  required
                  name="password"
                />
              </div>
            </label>
          </div>
        </div>
        <input
          onClick={handleSignUp}
          type="submit"
          value="Create my account"
          className="bg-gradient-to-r from-blue-400 to-blue-600 w-80 sm:w-96 py-4 rounded-full border-gray-400 text-neutral-8 cursor-pointer hover:bg-blue-700 transition duration-200 ease-out hover:ease-in"
        />
        <h5 className="text-gray-500 text-center px-3">
          By signing up, you agree to our
          {' '}
          <mark className="underline text-gray-500 bg-transparent">
            Privacy Policy
          </mark>
          {' '}
          and
          {' '}
          <mark className="underline text-gray-500 bg-transparent">
            Terms of Service
          </mark>
        </h5>
        <h5 className="text-gray-500 px-3 text-center">
          You already have an account ? Go to
          {' '}
          <Link
            to="../sign-in"
            className="underline text-blue-600 bg-transparent"
          >
            {' '}
            Sign in
          </Link>
        </h5> 
      </div>
    </div>
  );
}

export default SignUp;
