import React from 'react'


function Navbar() {
    return(
      <nav className="m-auto md:w-3/5 h-24">
        <div className="w-full h-full flex flex-row justify-center items-center border-b-2 flex-wrap md:flex-nowrap">
            <select className="btn-primary-outlined dark:bg-transparent dark:text-neutral-8 flex justify-between items-center py-1.5 hover-animation-no-scale">
              <option className="dark:bg-neutral-2 dark:text-neutral-8" value="recently">Recently added</option>
              <option className="dark:bg-neutral-2 dark:text-neutral-8" value="increasing">Price (increasing order)</option>
              <option className="dark:bg-neutral-2 dark:text-neutral-8" value="decreasing">Price (decreasing order)</option>
            </select>
          <div className="flex flex-row justify-center items-center gap-10 grow">
            <div className="text-xs text-gray-500 font-bold hover:text-white hover:bg-gray-600 p-1 rounded-full transition ease-in-out">All items</div>
            <div className="text-xs text-gray-500 font-bold hover:text-white hover:bg-gray-600 p-1 rounded-full transition ease-in-out">BTC</div>
            <div className="text-xs text-gray-500 font-bold hover:text-white hover:bg-gray-600 p-1 rounded-full transition ease-in-out">ETH</div>
            <div className="text-xs text-gray-500 font-bold hover:text-white hover:bg-gray-600 p-1 rounded-full transition ease-in-out">BNB</div>
            <div className="text-xs text-gray-500 font-bold hover:text-white hover:bg-gray-600 p-1 rounded-full transition ease-in-out">DOGE</div>
          </div>
        </div>
      </nav>
    )
}

export default Navbar