import React from "react";
import Photo from "../../../assets/profile_picture2.png"

function Testimonial({author, role, text}){
  return (
    <div className="w-[80vw] border md:w-[30vw]  h-fit rounded-xl p-6 flex flex-col gap-4 border-solid border-black dark:border-white">
      <div className="flex flex-col justify-start gap-6 flex-1 items-center md:flex-row">
        <img className="max-w-[50px] rounded-full" src={Photo} alt=""/>
        <div className="flex flex-col">
          <h1 className='text-sm font-extrabold'>{author}</h1>
          <h2 className='text-xs font-bold'>{role}</h2>
        </div>
      </div>
        <div className="max-h-[30vh] overflow-scroll md:overflow-auto">
          <p className="text-xs text-justify">
            {text}
          </p>
        </div>

    </div>
  )
}

Testimonial.defaultProps ={
  author: 'John Doe',
  role: 'Developer',
  text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
}

export default Testimonial;