import React from 'react';
import Navbar from "../../components/Navbar";
import NavbarNoAccount from '../../components/NavbarNoAccount';
import Footer from "../../components/Footer";
import Hero from "./Views/Hero";
import Features from "./Views/Features";
import Members from "./Views/Members";
import Testimonials from "./Views/Testimonials";
import Content from "./Views/Contents";

function LandingPage() {
  return (
    <div className="flex flex-col gap-24 overflow-hidden">
      <NavbarNoAccount/>
      <Navbar/>
      <Hero/>
      <Features/>
      <Content/>
      <Members/>
      <Testimonials/>
      <Footer/>
    </div>
  );
}

export default LandingPage;