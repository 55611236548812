import React from 'react';
import imageTmp from '../../../assets/image3.png'
import profilePicture from '../../../assets/profile_picture1.png'

function BotCard({image = imageTmp, botName = 'Bot2Ouf', price=40, creator='Curtis  '}){
  return(
    <div className="md:w-[15vw] flex flex-col hover-animation items-center justify-center">
      <div className="md:w-full w-1/2 h-max-35vh">
        <img src={image} alt="" className="md:w-full rounded-xl"/>
      </div>
      <div className="w-full flex flex-col md:justify-between py-2 gap-3 items-center justify-center">
        <div className="w-full flex flex-row md:justify-between justify-center items-center gap-3">
          <h1 className="font-semibold tracking-wider">
            {botName}
          </h1>
          <div className="text-primary-4 font-bold border-2 tracking-tight border-primary-4	border-solid rounded text-[0.7rem] m-0 p-1">
          {price}$/MONTHS
          </div>
        </div>
        <div className="w-full flex flex-row justify-center md:justify-between items-center gap-3">
          <img src={profilePicture} alt="" className="rounded-full w-7 h-7 " />
          <h1 className="font-semibold tracking-wider text-sm">
            {creator}
          </h1>
        </div>
      </div>
    </div>
  )
}

export default BotCard