import React from 'react';
import BotCard from '../../../components/Homepage/BotCard';

function HotBots() {
  return (
    <div className="w-full md:h-[75vh] md:min-h-[40vh] md:p-5 pt-20 flex flex-col gap-10 m-0">
      <h1 className="headline-3 dark:text-neutral-8 text-neutral-2 font-bold px-20">Hot Bots</h1>
      <div className="flex flex-col md:flex-row w-full h-full pb-8 gap-20 justify-center items-center">
        <BotCard/>
        <BotCard/>
        <BotCard/>
        <BotCard/>
      </div>
    </div>
  );
}

export default HotBots;