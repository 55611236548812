import React, { useRef, useState } from 'react';
import { FiDownload, FiFile } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import Navbar from '../../../components/Navbar';
import Footer from '../../../components/Footer';

function Developer() {
  const [dragging, setDragging] = useState(false);
  const [fileContent, setFileContent] = useState('');
  const [fileName, setFileName] = useState('');
  const fileInputRef = useRef(null);

  // region Handlers
  const handleDragOver = (e) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setDragging(false);
    const file = e.dataTransfer.files[0];
    setFileName(file.name);

    const reader = new FileReader();
    reader.onload = (event) => {
      const content = event.target.result;
      setFileContent(content);
    };
    reader.readAsText(file);
  };

  const handleSelectFile = () => {
    fileInputRef.current.click();
  };

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    setFileName(file.name);

    const reader = new FileReader();
    reader.onload = (event) => {
      const content = event.target.result;
      setFileContent(content);
    };
    reader.readAsText(file);
  };
  // endregion

  return (
    <>
      <Navbar />
      <div className="flex items-center justify-center gap-2 mt-12 mx-auto flex-col">
        <div
          className={`w-[640px] h-[182px] bg-gray-100 rounded-md my-[50px]${
            dragging ? 'border-primary-1' : ''
          }`}
          onDragOver={handleDragOver}
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
        >
          {!fileContent && (
            <div className="flex flex-col items-center justify-center h-full">
              <button type="button"
                className="bg-gray-100 text-gray-400 px-4 py-2 rounded-md"
                onClick={handleSelectFile}
              >
                <FiFile className="mr-2" />
                <FiDownload className="mr-2" />
              </button>
              <input
                type="file"
                ref={fileInputRef}
                className="hidden"
                onChange={handleFileInputChange}
              />
              <p className="text-gray-400">PNG, JGP, JPEG, GIF, BMP, PDF</p>
            </div>
          )}
          {fileName
            && (() => {
              const allowedExtensions = [
                '.png',
                '.jpg',
                '.jpeg',
                '.gif',
                '.bmp',
                '.pdf',
              ];
              const fileExtension = fileName
                .toLowerCase()
                .substring(fileName.lastIndexOf('.'));
              if (allowedExtensions.includes(fileExtension)) {
                return (
                  <div className="p-4">
                    <h2 className="text-medium font-semibold">File Name:</h2>
                    <p>{fileName}</p>
                  </div>
                );
              }
              return (
                <div className="p-4">
                  <h2 className="text-medium font-semibold">File Name:</h2>
                  <p className="text-red-500">
                    Invalid file extension. Please select a file with .png,
                    .jpg, .jpeg, .gif, .bmp, or a .pdf extension.
                  </p>
                </div>
              );
            })()}
        </div>
        <Link to="../profile" className="btn-primary-outlined">
          <input
            type="submit"
            value="Back to profile"
            className="cursor-pointer"
          />
        </Link>
      </div>
      <Footer />
    </>
  );
}

export default Developer;
