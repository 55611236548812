import React from 'react';
import { Link } from "react-router-dom";
import image1 from '../../../assets/image1.png';
import profilePicture from '../../../assets/profile_picture.png';

function MainPage() {
  return (
    <div className='flex h-full md:h-[calc(100vh-6rem)] w-screen sm:w-full flex-col align-center justify-evenly mb-20 gap-4 md:gap-1'>
      <div className="w-full h-1/4 flex flex-col items-center justify-center">
        <p className="text-xs text-neutral-4 font-bold text-center">
          {' '}
          CREATE, EXPLORE AND RENT BOTS.
        </p>
        <h1 className="text-3xl font-bold tracking-wider text-center md:text-start">
          The new creative way to trade.
        </h1>
        <Link to="/marketplace">
          <input type="submit" value="Start your search" className="mt-6 btn-primary-outlined dark:text-neutral-8 font-normal"/>
        </Link>
      </div>
      <div className="h-4/6 flex flex-col md:flex-row  justify-center items-center md:items-start sm:justify-around">
        <div className="bg-pink-200 rounded-3xl sm:w-1/3 w-2/3">
          <img
            src={image1}
            alt="Bot test"
            className="w-full  h-full rounded-3xl object-cover object-center"
          />
        </div>
        <div className="md:w-[30vw] h-full min-h-[180px] flex flex-col justify-between gap-8 md:gap-1">
          <h1 className="text-[60px] font-bold tracking-wider">Aizy Bot</h1>
          <div className="-mt-8 flex flex-row justify-between ">
            <div className="w-1/2 flex flex-row items-center justify-start gap-3 ">
              <div className="w-[30px] h-[30px]">
                <img
                  src={profilePicture}
                  alt=""
                  className="w-full h-full rounded-lg object-cover object-center"
                />
              </div>
              <div>
                <p className="text-[13px] text-neutral-4">Creator</p>
                <h2 className="text-[16px] font-bold">Curtis</h2>
              </div>
            </div>
            <div className="md:w-1/2">
              <p className="text-[13px] text-neutral-4">Rent for</p>
              <h2 className="text-[16px] font-bold">40$/months</h2>
            </div>
          </div>
          <div className="mt-8 p-8 md:w-5/6 md:h-2/5 rounded-3xl dark:border-none border-2 border-neutral-6 dark:bg-neutral-2 flex flex-col items-center justify-center">
            <p className="hairline dark:text-neutral-8 text-neutral-1 mt-2">
              Benefits of this bot
            </p>
            <h1 className="headline-2 font-bold">54%</h1>
            <h2 className="-mt-4 mb-4 dark:text-neutral-4 body-1 font-semibold">$3.618.36</h2>
            <div className="w-full h-full flex items-center justify-evenly">
              <p className="text-s font-semibold">Pair: BTC</p>
              <p className="text-s font-semibold">Profit Factor: 1</p>
            </div>
          </div>
          <div className="flex flex-col gap-3 items-center md:items-start">
            <div
              className="w-5/6 h-[5vh] dark:border-none border-2 rounded-3xl bg-blue-600 flex flex-col items-center justify-center text-white font-semibold"
            >
              Rent This Bot
            </div>
            <div
              className="w-5/6 h-[5vh] dark:border-neutral-3 border-2 rounded-3xl border-solid border-gray-300 flex flex-col items-center justify-center font-semibold"
            >
              Add to cart
            </div>
          </div>
        </div>
      </div>
      <div/>
    </div>
  )
}

export default MainPage;