import React, { useState } from 'react';
import { FacebookShareButton, TwitterShareButton, LinkedinShareButton, FacebookIcon, TwitterIcon, LinkedinIcon } from 'react-share';
import { IoCloseOutline } from 'react-icons/io5';

function SharePopup({ isOpen, url, title, onClose }) {
  const [customMessage, setCustomMessage] = useState('');

  if (!isOpen) return null;

  return (
    <div className="fixed  inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
      <div className="bg-white dark:bg-neutral-1 p-4 rounded-lg shadow-lg space-y-4">
      <div className="flex justify-between items-center w-full">
          <div className="flex items-center space-x-3">
            <p className="text-neutral-2 text-[20px] font-bold dark:text-neutral-8">Aizy</p>
            <div className="bg-neutral-6 h-6 w-[2px] font-bold"/>
            <p className="text-neutral-4 text-[16px]">Share</p>
          </div>
          <button type="button" className="w-8 h-8 bg-gray-300 dark:bg-gray-500 rounded-full flex items-center justify-center hover-animation" onClick={onClose}>
            <IoCloseOutline className="text-gray-500 dark:text-gray-300" size={18} />
          </button>
        </div>
        <textarea
          className="w-full p-2 text-neutral-800 bg-white dark:bg-neutral-1 shadow-md rounded-lg border border-neutral-300"
          placeholder="Enter your message..."
          value={customMessage}
          onChange={(e) => setCustomMessage(e.target.value)}
        />
        <div className="flex justify-center space-x-4">
          <FacebookShareButton url={url} quote={`${title  } ${  customMessage}`}>
            <FacebookIcon size={40} round />
          </FacebookShareButton>
          <TwitterShareButton url={url} title={`${title  } ${  customMessage}`}>
            <TwitterIcon size={40} round />
          </TwitterShareButton>
          <LinkedinShareButton url={url}>
            <LinkedinIcon size={40} round />
          </LinkedinShareButton>
        </div>
      </div>
    </div>
  );
}

export default SharePopup;
