import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { AuthContext } from '../../../AuthContext';
import API_URL from "../../../utils/api";

function SignIn() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  // const [token, setToken] = useState('');

  const { login } = useContext(AuthContext);

  const handleSignIn = () => {
    const headers = {
      Authorization: process.env.REACT_APP_API_KEY,
    };

    axios
      .post(
        `${API_URL}/v1/login`,
        {
          username,
          password,
        },
        {
          headers,
        },
      )
      .then((res) => {
        const userToken = res.data.token;
        const user = {
          username,
          email,
        };

        sessionStorage.setItem('user', JSON.stringify(user));
        sessionStorage.setItem('userToken', JSON.stringify(userToken));
        login(user);
        window.location.href = '/home';
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.log(e);
      });
  };

  return (
    <div className="w-full flex flex-row h-screen">
      <div className="flex justify-center items-center flex-col w-screen gap-8 mx-3 flex-1">
        <h1 className="title">Login</h1>
        <div className="buttons mt-16">
          <div className="username-btn w-80 sm:w-96">
            <label
              htmlFor={username}
              className="block font-medium leading-6 text-xl text-gray-900"
            >
              Username
              <div className="relative mt-2 rounded-md shadow-sm w-full">
                <input
                  type="text"
                  placeholder="Username"
                  name="username"
                  id="username"
                  value={username}
                  className="block w-full rounded-xl border-0 py-2 pl-4 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  onChange={(e) => setUsername(e.target.value) && setEmail(e.target.value)}
                  required
                />
              </div>
            </label>

          </div>
          <div className="password-btn mt-4 w-80 sm:w-96">
            <label
              htmlFor="password"
              className="block font-medium leading-6 text-xl text-gray-900"
            >
              Password
              <div className="relative mt-2 rounded-md shadow-sm">
                <input
                  type="password"
                  placeholder="Password"
                  name="password"
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="block rounded-xl border-0 py-2 w-80 sm:w-96 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  required
                />
              </div>
            </label>
          </div>
        </div>
        <input
          onClick={handleSignIn}
          type="submit"
          value="Sign In"
          className="bg-gradient-to-r from-blue-400 to-blue-600 w-80 sm:w-96 py-4 rounded-full border-gray-400 text-neutral-8 cursor-pointer hover:bg-blue-700 transition duration-200 ease-out hover:ease-in"
        />
        <h5 className="text-gray-500 text-center">
          By signing in, you agree to our
          {' '}
          <mark className="underline text-gray-500 bg-transparent">
            Privacy Policy
          </mark>
          {' '}
          and
          {' '}
          <mark className="underline text-gray-500 bg-transparent">
            Terms of Service
          </mark>
        </h5>
        <h5 className="text-gray-500 mt-16 text-center">
          Don&apos;t have an account?
          {' '}
          <Link
            to="../sign-up"
            className="underline text-blue-600 bg-transparent"
          >
            Sign up
          </Link>
        </h5>
      </div>
    </div>
  );
}

export default SignIn;
