import React from "react";
 import William from "../../../assets/tmp/PP1.png";

function MemberCard({name, role, photo}){
  return (
    <div className="group relative w-[15vw] min-w-[150px] rounded-xl">
      <img className=" rounded-xl" src={photo} alt=""/>
      <div
        className="absolute inset-0 rounded-xl bg-black opacity-0 group-hover:opacity-60 transition-opacity flex flex-col items-center justify-center">
        <h2 className="text-white text-2xl mb-2">{name}</h2>
        <p className="text-white text-center">{role}</p>
      </div>
    </div>
  )
}

MemberCard.defaultProps = {
  name: "William",
  role: "Front-End",
  photo: William
};

export default MemberCard;