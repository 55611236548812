import React, {useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import {AiOutlineSearch} from 'react-icons/ai';
import {BsFillCaretDownFill} from 'react-icons/bs';
import axios from 'axios';
import MultiRangeSlider from '../../components/MultiRangeSlider/MultiRangerSlider';
import Footer from '../../components/Footer';
import BotCard from '../../components/BotCard';
import Navbar from '../../components/Navbar';
import API_URL from "../../utils/api";

function Marketplace() {
  const [onInputClick, setOnInputClick] = useState('All items');
  const [isSearchInput, setIsSearchInput] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const [bots, setBots] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState('recent-creation');
  const navigate = useNavigate();

  // region Handlers
  const handleItemClick = (itemName) => {
    setOnInputClick(itemName);
  };

  const handleChange = (e) => {
    e.preventDefault();
    setSearchInput(e.target.value);
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleDropdownChange = (e) => {
    const {value} = e.target;
    setSelectedValue(value);
  };
  // endregion

  // region Search
  // const filteredCountries = countries.filter((country) =>
  //   country.name.toLowerCase().includes(searchInput.toLowerCase())
  // );

  const showSearch = () => (isSearchInput ? (
    <input
      type="search"
      placeholder="Search here"
      onChange={handleChange}
      value={searchInput}
    />
  ) : null);
  // endregion

  // region API Calls
  const getBotCards = () => {
    console.log(sessionStorage.getItem("userToken"))
    axios.post(`${API_URL}/v1/bot/list`,{
      sort: selectedValue === "recent-creation" ? "-createdAt" : "-updatedAt",
    }, {
      headers: {
        Authorization: process.env.REACT_APP_API_KEY,
        'access-token': sessionStorage.getItem("userToken"),
      },
    }).then((res) => {
      setBots(res.data.data);
    })
      .catch((error) => {
        console.error("Error fetching bots:", error);
      }
    );
  };

  useEffect(() => {
    getBotCards();
  }, [selectedValue]);

  const handleBotCardClick = (clickedUuid, botData) => {
    navigate(`/bot-detail/${clickedUuid}`, { state: { botData } });
  };
  // endregion

  // region Renders
  const renderFilters = () => (
    <div className="sm:w-3/12 h-8 flex justify-between gap-6 ">
      <input
        type="submit"
        name="All items"
        value="All items"
        className={
          onInputClick !== "All items"
            ? 'neutral-4 font-semibold hover-animation'
            : 'btn-primary hover-animation'
        }
        onClick={() => handleItemClick('All items')}
      />
      <input
        type="submit"
        name="SPOT"
        value="SPOT"
        className={
          onInputClick !== 'SPOT'
            ? 'neutral-4 font-semibold hover-animation'
            : 'btn-primary hover-animation'
        }
        onClick={() => handleItemClick('SPOT')}
      />
      <input
        type="submit"
        name="PERP"
        value="PERP"
        className={
          onInputClick !== 'PERP'
            ? 'neutral-4 font-semibold hover-animation'
            : 'btn-primary hover-animation'
        }
        onClick={() => handleItemClick('PERP')}
      />
    </div>
  );

  const renderItemsFilters = () => (
    <div className="w-full">
      <div className="relative">
        <select onClick={toggleDropdown} onChange={handleDropdownChange} className="btn-primary-outlined bg-none w-full flex justify-between dark:bg-transparent dark:text-neutral-8 items-center py-1.5 hover-animation-no-scale appearance-none">
          <option className="dark:bg-neutral-2">Filter by</option>
          <option className="dark:bg-neutral-2" value="recent-creation">Recent creation</option>
          <option className="dark:bg-neutral-2" value="recent-update">Recent update</option>
        </select>
        <div
          className={`absolute inset-y-0 right-0 flex items-center px-4 pointer-events-none transition-transform duration-300 ${
            isOpen ? 'transform rotate-180' : ''
          }`}
        >
          <BsFillCaretDownFill className="w-3 h-3 dark:text-neutral-8" />
        </div>
      </div>
      <div className="mt-12">
        <label className="text-neutral-5 font-bold text-xs" htmlFor="default-range">
          PRICE RANGE
          <MultiRangeSlider id="default-range" min={10} max={5000} onChange={({min, max}) => console.log(`min = ${min}, max = ${max}`)}/>
        </label>

        <hr className="mt-16 border border-neutral-6/50"/>
        <div className="flex flex-col gap-4 mt-6">
          <div>
            <label className="text-neutral-5 font-bold text-xs" htmlFor="first-btn">
              FILTER BY
            </label>
            <div className="relative">
              <select className="btn-primary-outlined w-full flex justify-between items-center py-1.5 hover-animation-no-scale">
                <option value="most-liked">Most liked</option>
                {/* Other options */}
              </select>
              <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
                <BsFillCaretDownFill className="w-3 h-3 dark:text-neutral-8"/>
              </div>
            </div>
          </div>
          <div>
            <label className="text-neutral-5 font-bold text-xs" htmlFor="second-btn">
              CREATOR
            </label>
            <div className="relative">
              <select className="btn-primary-outlined w-full flex justify-between items-center py-1.5 hover-animation-no-scale">
                <option value="verified-only">Verified only</option>
                {/* Other options */}
              </select>
              <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
                <BsFillCaretDownFill className="w-3 h-3 dark:text-neutral-8"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );


  const renderCards = () => (
    <div className="my-16 ml-16 flex items-center justify-between gap-8 flex-wrap">
      {bots.map((bot, index) => (
        <BotCard
          key={index}
          name={bot.name}
          price={bot.price}
          currency={bot.crypto}
          author={bot.author}
          uuid={bot.uuid}
          onClick={(clickedUuid) => handleBotCardClick(clickedUuid, bot)}
        />
      ))}
    </div>
  );
  // endregion

  return (
    <div className="w-screen">
      <Navbar/>
      <div className="mt-20 mx-auto max-w-8xl px-4 sm:px-6 lg:px-8 flex items-center justify-center flex-col w-screen">
        <div
          className="flex w-full mb-12 justify-between items-center px-8 py-8 border-b-2 border-b-gray-100 dark:border-neutral-3 flex-col md:flex-row">
          <h1 className="title">Marketplace</h1>
          <AiOutlineSearch
            className="hover-animation background-primary-1 w-8 h-8 p-2 rounded-full text-neutral-8 cursor-pointer"
            onClick={() => setIsSearchInput(!isSearchInput)}
          />
          {showSearch()}
        </div>
        <div className="w-full">
          <div className="flex flex-col md:flex-row md:items-start justify-between">
            <div className="w-full md:w-1/4">{renderItemsFilters()}</div>
            <div className="md:w-3/4 flex md:justify-end md:items-end flex-col mt-2">
              {renderFilters()}
              <div className="flex flex-col md:flex-row items-center justify-center w-full">
                {renderCards()}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  );
}

export default Marketplace;
