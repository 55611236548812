import React from 'react';
import { FiArrowRight } from 'react-icons/fi';
import Footer from '../../components/Footer';
import Navbar from '../../components/Navbar';

import notFound from '../../assets/not-found-figure.png';

function NotFound() {
  return (
    <>
      <Navbar />
      <div className="flex flex-col items-center justify-center h-screen bg-neutral-8 dark:bg-neutral-1">
        <div className="w-[1120px] h-[397px] mt-[60px] mb-[66px] flex items-center justify-center">
          <img src={notFound} alt="" className="max-h-full max-w-full" />
        </div>
        <h1 className="text-[48px] font-bold text-center w-2/5 text-neutral-2 dark:text-neutral-7 mb-[-20px] pb-8">
          Sorry, we couldn&apos;t find any  results for this search.
        </h1>
        <p className="text-[14px] text-neutral-4 mt-2 mb-[20px]">
          Maybe give one of these a try?
        </p>
        <div className="mt-4 flex">
          <div className="relative flex-grow">
            <input
              type="text"
              placeholder="Enter your search..."
              className="border-[2px] border-neutral-6 rounded-full px-6 py-3 focus:outline-none w-full text-neutral-1 dark:text-neutral-8 dark:bg-transparent pr-[100px]"
            />
            <button type="button" className="absolute top-[8px] right-[8px] bg-primary-1 rounded-full p-2 flex items-center ">
              <FiArrowRight className="text-neutral-8" />
            </button>
          </div>
        </div>
        <button type="button" className="bg-primary-1 text-neutral-8 font-bold py-3 px-6 rounded-full hover-animation mt-[24px]">
          <a href="/marketplace">Return to the marketplace</a>
        </button>
      </div>
      <Footer />
    </>
  );
}

export default NotFound;
