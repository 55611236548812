import React from 'react';
import image0 from '../../../assets/image6.png'
import image1 from '../../../assets/image7.png'
import image2 from '../../../assets/image8.png'
import image3 from '../../../assets/image9.png'
import profilePicture from '../../../assets/profile_picture1.png'

function Tags({images, title = 'Awesome Title', author = 'Curtis', nbItems = 28}) {
  if (!images) {
    // eslint-disable-next-line no-param-reassign
    images = [
      image0,
      image1,
      image2,
      image3
    ]
  }
  return <div className='w-80 h-96 hover-animation'>
    <div className="h-5/6 grid grid-cols-3 grid-rows-3 gap-2">
      <div className="w-full h-full col-span-3 row-span-2 rounded-xl">
        <img className="object-cover h-full w-full object-center rounded-xl" src={images[0]} alt=""/>
      </div>
      <div className=" col-span-1 row-span-1 rounded-xl">
        <img className="object-cover h-full w-full rounded-xl" src={images[1]} alt=""/>
      </div>
      <div className="col-span-1 row-span-1 rounded-xl">
        <img className="object-cover h-full w-full rounded-xl" src={images[2]} alt=""/>
      </div>
      <div className="col-span-1 row-span-1 rounded-xl">
        <img className="object-cover h-full w-full rounded-xl" src={images[3]} alt=""/>
      </div>
    </div>
    <div className="text-2xl font-bold py-2">{title}</div>
    <div className="flex items-center justify-between w-full">
      <div className="flex items-center gap-2">
      <img className="rounded-full w-8 h-8" src={profilePicture} alt=""/>
        <div className="text-xs ">By {author}</div>
      </div>
      <div className="text-xs font-bold border-solid border-2 border-gray-200 rounded p-[2px] ">{nbItems} ITEMS</div>
    </div>
  </div>;
}

export default Tags;