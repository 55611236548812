import React, {useRef, useState} from 'react';
import {FaArrowRight, FaPlus} from 'react-icons/fa';

import {FiDownload, FiFile} from 'react-icons/fi';

import {Link, useNavigate} from 'react-router-dom';
import axios from 'axios';
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import API_URL from "../../utils/api";

function Upload() {
  const [itemName, setItemName] = useState('');
  const [description, setDescription] = useState('');
  const [price, setPrice] = useState('');
  const [currency, setCurrency] = useState('');
  const [dragging, setDragging] = useState(false);
  const [fileContent, setFileContent] = useState('');
  const [fileName, setFileName] = useState('');
  const fileInputRef = useRef(null);
  const navigate = useNavigate();
  const isDev = localStorage.getItem('isDev') === 'true';

  const handleItemNameChange = (e) => {
    setItemName(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handlePriceChange = (e) => {
    setPrice(e.target.value);
  };

  const handleCurrencyChange = (e) => {
    setCurrency(e.target.value);
  };

  const postBot = (itemName, description, price, currency) => {
    console.log(itemName);

    axios
      .post(
        `${API_URL}/v1/user/bot/create`,
        {
          name: itemName,
          description,
          price,
          crypto: currency,
        },
        {
          headers: {
            Authorization: process.env.REACT_APP_API_KEY,
            'access-token': sessionStorage.getItem("userToken"),
          },
        },
      )
      .then(() => {
        navigate('/upload/succeeded');
      });
  };

  const handleFormSubmit = (e) => {
    const validCurrencies = ['EUR', 'USD', 'GBP', 'BTC', 'ETH'];
    e.preventDefault();

    if (!itemName || !description || !price || !currency) {
      window.alert('Please fill in all the required fields');
      return;
    }

    if (!/^\d+$/.test(price)) {
      window.alert('Price must be a valid number');
      return;
    }

    if (!validCurrencies.includes(currency.toUpperCase())) {
      window.alert('Currency must be a valid currency abbreviation');
      return;
    }

    setItemName(itemName);
    setDescription(description);
    setPrice(price);
    setCurrency(currency);

    postBot(itemName, description, parseFloat(price), currency);

    console.log('Item Name:', itemName);
    console.log('Description:', description);
    console.log('Price:', price);
    console.log('Currency:', currency);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setDragging(false);
    const file = e.dataTransfer.files[0];
    setFileName(file.name);

    const reader = new FileReader();
    reader.onload = (event) => {
      const content = event.target.result;
      setFileContent(content);
    };
    reader.readAsText(file);
  };

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    setFileName(file.name);

    const reader = new FileReader();
    reader.onload = (event) => {
      const content = event.target.result;
      setFileContent(content);
    };
    reader.readAsText(file);
  };

  const handleSelectFile = () => {
    fileInputRef.current.click();
  };

  return (
    <>
      <Navbar/>
      {isDev ? (
        <div className="container md:mt-[128px] mx-auto p-4 w-screen flex flex-col">
          <h1 className="text-[48px] font-bold mb-[40px] text-center md:text-left">Upload</h1>
          <h1 className="text-[16px] font-medium mb-[4px]">Upload file</h1>
          <p className="text-[12px] text-gray-500 mb-[16px]">
            Drag or choose your file to upload
          </p>
          <div
            className={`md:w-[640px] md:h-[182px] h-[40vw] bg-gray-100 rounded md:rounded-md mb-[40px]${
              dragging ? 'border-primary-1' : ''
            }`}
            onDragOver={handleDragOver}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
          >
            {!fileContent && (
              <div className="flex flex-col items-center justify-center h-full w-screen md:w-full">
                <button type="button"
                        className="bg-gray-100 text-gray-400 px-4 py-2 rounded-md"
                        onClick={handleSelectFile}
                >
                  <FiFile className="mr-2"/>
                  <FiDownload className="mr-2"/>
                </button>
                <input
                  type="file"
                  ref={fileInputRef}
                  className="hidden"
                  onChange={handleFileInputChange}
                />
                <p className="text-gray-400">C, C++, PY, JS, TS</p>
              </div>
            )}
            {fileName
              && (() => {
                const allowedExtensions = ['.c', '.cpp', '.py', '.js', '.ts'];
                const fileExtension = fileName
                  .toLowerCase()
                  .substring(fileName.lastIndexOf('.'));
                if (allowedExtensions.includes(fileExtension)) {
                  return (
                    <div className="p-4">
                      <h2 className="text-medium font-semibold">File Name:</h2>
                      <p>{fileName}</p>
                    </div>
                  );
                }
                return (
                  <div className="p-4">
                    <h2 className="text-medium font-semibold">File Name:</h2>
                    <p className="text-red-500">
                      Invalid file extension. Please select a file with .c,
                      .cpp, .py, .js, or .ts extension.
                    </p>
                  </div>
                );
              })()}
          </div>
          <div className="w-screen">
            <h1 className="text-[16px] font-medium mb-[32px]">Item details</h1>
            <form onSubmit={handleFormSubmit}>
              <div className="mb-[32px]">
                <label
                  htmlFor="itemName"
                  className="block text-gray-400 text-[12px] font-medium mb-[16px]"
                >
                  Item Name
                </label>
                <input
                  type="text"
                  placeholder='e. g. "My bot"'
                  id="itemName"
                  value={itemName}
                  onChange={handleItemNameChange}
                  className="border border-gray-300 text-[14px] rounded md:w-[640px] px-2 py-1 w-[90vw]"
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="description"
                  className="block text-gray-400 text-[12px] font-medium mb-[16px]"
                >
                  Description
                </label>
                <textarea
                  id="description"
                  placeholder='e. g. "The best in universe."'
                  value={description}
                  onChange={handleDescriptionChange}
                  className="border border-gray-300 text-[14px] rounded md:w-[640px] px-2 py-1 w-[90vw]"
                />
              </div>
              <div className="flex md:flex-row flex-col mb-4 w-screen">
                <div className="md:mr-[20px] w-screen">
                  <label
                    htmlFor="price"
                    className="block text-gray-400 text-[12px] font-medium mb-[16px]"
                  >
                    Price
                  </label>
                  <input
                    type="text"
                    placeholder='e. g. "10$"'
                    id="price"
                    value={price}
                    onChange={handlePriceChange}
                    className="border border-gray-300 text-[14px] rounded w-[90vw]  md:w-[310px] px-2 py-1"
                  />
                </div>
                <div>
                  <label
                    htmlFor="currency"
                    className="block text-gray-400 text-[12px] font-medium mb-[16px]"
                  >
                    Currency
                  </label>
                  <input
                    type="text"
                    placeholder='e. g. "BTC"'
                    id="currency"
                    value={currency}
                    onChange={handleCurrencyChange}
                    className="border border-gray-300 text-[14px] rounded w-[90vw]  md:w-[310px] px-2 py-1"
                  />
                </div>
              </div>
              <button
                type="submit"
                className="bg-primary-1 text-neutral-8 px-4 py-2 rounded-md mt-[16px] hover-animation text-center md:text-left w-[90vw] md:w-[640px]"
              >
                Upload your bot
              </button>
            </form>
          </div>

          <div className="mt-[40px]">
            <hr className="border-gray-200"/>
          </div>
          <h1 className="text-[16px] font-medium mt-[40px]">
            Choose collection
          </h1>
          <p className="text-[12px] text-gray-500 mt-[4px] mb-[24px]">
            Choose an exiting collection or create a new one
          </p>
          {/* TODO: Remove absolute and relative postion and put eerything into flexbox */}
          <div className="flex overflow-x-auto gap-4 text-xs md:text-base">
            <div className="relative bg-gray-100 w-[160px] h-[124px] rounded-md overflow-auto">
              <div
                className="absolute top-[24px] left-[24px] w-[32px] h-[32px] bg-black rounded-full flex items-center justify-center">
                <FaPlus className="text-neutral-8"/>
              </div>
              <p className="absolute bottom-2 left-[12px] md:left-[24px] text-gray-700">
                Create collection
              </p>
            </div>
            <div className="relative bg-gray-100 w-[160px] h-[124px] rounded-md overflow-auto">
              <div className="absolute top-[24px] left-[24px] w-[32px] h-[32px] bg-primary-4 rounded-full"/>
              <p className="absolute bottom-2  left-[12px] md:left-[24px] text-gray-700">
                Crypto Legend - Professor
              </p>
            </div>
            <div className="relative bg-gray-100 w-[160px] h-[124px] rounded-md overflow-auto">
              <div className="absolute top-[24px] left-[24px] w-[32px] h-[32px] bg-primary-3 rounded-full"/>
              <p className="absolute bottom-2 left-[12px] md:left-[24px]  text-gray-700">
                Crypto Legend - Professor
              </p>
            </div>
            <div className="relative bg-gray-100 w-[160px] h-[124px] rounded-md overflow-auto">
              <div className="absolute top-[24px] left-[24px] w-[32px] h-[32px] bg-primary-2 rounded-full"/>
              <p className="absolute bottom-2 left-[12px] md:left-[24px] text-gray-700">
                Legend Photography
              </p>
            </div>
          </div>
          {/* avec le hover au cas ou
      <button type="button" className="bg-blue-500 hover:bg-blue-700 text-neutral-8 font-bold py-2 px-4 rounded"> */}
          <div className="self-center flex md:flex-row flex-col md:grid-cols-4 gap-10 md:gap-[380px] mt-[40px] text-center">
            <button type="button"
                    className="bg-primary-1 text-neutral-8 font-medium py-2 px-4 rounded-full flex items-center hover-animation">
              <span className="mr-2">Create item</span>
              <FaArrowRight/>
            </button>
            <div className="flex items-center justify-center ">
              <p className="text-black font-bold text-[16px] mr-2">
                Auto Saving
              </p>
              <div className="w-6 h-6 border-t-2 border-gray-500 rounded-full animate-spin"/>
            </div>
          </div>
          <Footer/>
        </div>
      ) : (
        <div className="mt-20 flex flex-col justify-between h-[50rem] gap-4 items-center">
          <p className="text-center mt-12">
            You are not a developer, please go to the
            {' '}
            <Link to="/profile/developer" className="text-primary-1">
              Developer page
            </Link>
            {' '}
            and submit your documents.
          </p>
          <Link to="../marketplace" className="btn-primary-outlined">
            <input
              type="submit"
              value="Back to marketplace"
              className="cursor-pointer"
            />
          </Link>
        </div>
      )}
    </>
  );
}

export default Upload;
