import React, {useState, useContext} from 'react';
import {BiCircle, BiUpload, BiDotsHorizontalRounded, BiImageAlt} from 'react-icons/bi';
import {TbWorldWww, TbPencil} from 'react-icons/tb';
import {FiTwitter, FiInstagram, FiFacebook} from 'react-icons/fi';

import {Link} from 'react-router-dom';
import Footer from '../../components/Footer';
import Navbar from '../../components/Navbar';
import {AuthContext} from '../../AuthContext';
import BotCard from "../../components/BotCard";

import banner from '../../assets/banner.png';

function Profile() {
    const [onInputClick, setOnInputClick] = useState('OnSale');
    const storedProfilePicture = localStorage.getItem('userProfilePicture');

    const setIsDev = (value) => {
        localStorage.setItem('isDev', value);
    };

    const {logout} = useContext(AuthContext);
    const storedData = JSON.parse(sessionStorage.getItem('user'));

    const isDev = localStorage.getItem('isDev') === 'true';
    const user = sessionStorage.getItem('user');

    // region Handlers
    const handleItemClick = (itemName) => {
        setOnInputClick(itemName);
    };
    // endregion

    // region Renders
    const renderProfileInfos = () => (
        <div
            className=" w-4/5 sm:w-1/5 flex items-center flex-col justify-center p-8 bg-neutral-8 dark:bg-neutral-2 shadow-lg rounded-2xl sm:-mt-10">
            <div className="bg-blue-600 rounded-full w-36 h-36">
                <img
                    src={storedProfilePicture}
                    alt=""
                    className="w-full h-full rounded-full object-cover object-center"
                />
            </div>
            <h2 className="subtitle mt-8 mb-1">{storedData.username}</h2>
            {isDev ? (
                <h3 className="text-lg font-medium flex justify-around items-center gap-2">
                    Developer mode
                    {' '}
                    <BiCircle className="text-primary-4 mt-0.5"/>
                </h3>
            ) : (
                <Link
                    onClick={() => setIsDev(true)}
                    to="/profile/developer"
                    className="text-lg font-medium flex justify-around items-center gap-2"
                >
                    Developer mode
                    {' '}
                    <BiCircle className="text-primary-3 mt-0.5"/>
                </Link>
            )}
            <h4 className="text-center font-normal text-neutral-4 mt-4">
                Fondateur de DG, je suis le leader de la cryptomonnaie en france.
            </h4>
            <h4 className="mt-4 text-md font-medium flex items-center justify-around gap-2">
                <TbWorldWww className="text-neutral-4 mt-0.5"/>
                https://curtis.me
            </h4>
            <div className="mt-6 w-full gap-2 flex items-center justify-center">
                <input
                    type="submit"
                    className="px-4 py-2 cursor-pointer bg-primary-1 hover-animation font-semibold text-neutral-7 rounded-3xl"
                    value="Follow"
                />
                <button type="button"
                        className="btn-primary-outlined px-3 rounded-full hover-animation dark:text-neutral-8">
                    <BiUpload/>
                </button>
                <button type="button"
                        className="btn-primary-outlined px-3 rounded-full hover-animation dark:text-neutral-8">
                    <BiDotsHorizontalRounded/>
                </button>
            </div>
            <div className="btn-primary-outlined bg-transparent hover-animation text-neutral-8 flex gap-2 my-2 py-2">
                <input
                    type="submit"
                    className="bg-transparent cursor-pointer text-black dark:text-white"
                    value="Edit cover photo"
                />
                <BiImageAlt size={20} className="mt-0.5"/>
            </div>
            <Link
                to="/profile/edit"
                className="btn-primary-outlined h-fit bg-transparent hover-animation text-neutral-8 flex gap-2 my-2 py-2"
            >
                <input
                    type="submit"
                    className="bg-transparent cursor-pointer text-black dark:text-white"
                    value="Edit profile"
                />
                <TbPencil size={20} className="mt-0.5"/>
            </Link>

    <div className="mt-6 pb-6 border-b border-neutral-6 w-full flex items-center justify-center gap-4">
        <FiTwitter size={20} className="text-neutral-4 hover-animation"/>
        <FiInstagram size={20} className="text-neutral-4 hover-animation"/>
        <FiFacebook size={20} className="text-neutral-4 hover-animation"/>
    </div>
    <input
        onClick={() => logout(user)}
        type="submit"
        value="Disconnect"
        className="btn-primary bg-primary-3 hover-animation mt-4"
    />
    <p className="mt-4 text-center text-neutral-4">
        Member since Apr 15, 2023
    </p>
</div>
)
    ;

    const renderFilters = () => (
        <div className="sm:w-1/2 sm:h-8 mb-8 gap-4 flex justify-between  flex-wrap md:wrap">
            <input
                type="submit"
                name="OnSale"
                value="On Sale"
                className={
                    onInputClick !== 'OnSale'
                        ? 'neutral-4 font-semibold hover-animation'
                        : 'btn-primary hover-animation'
                }
                onClick={() => handleItemClick('OnSale')}
            />
            <input
                type="submit"
                name="Collectibles"
                value="Collectibles"
                className={
                    onInputClick !== 'Collectibles'
                        ? 'neutral-4 font-semibold hover-animation'
                        : 'btn-primary hover-animation'
                }
                onClick={() => handleItemClick('Collectibles')}
            />
            <input
                type="submit"
                name="Created"
                value="Created"
                className={
                    onInputClick !== 'Created'
                        ? 'neutral-4 font-semibold hover-animation'
                        : 'btn-primary hover-animation'
                }
                onClick={() => handleItemClick('Created')}
            />
            <input
                type="submit"
                name="Likes"
                value="Likes"
                className={
                    onInputClick !== 'Likes'
                        ? 'neutral-4 font-semibold hover-animation'
                        : 'btn-primary hover-animation'
                }
                onClick={() => handleItemClick('Likes')}
            />
            <input
                type="submit"
                name="Following"
                value="Following"
                className={
                    onInputClick !== 'Following'
                        ? 'neutral-4 font-semibold hover-animation'
                        : 'btn-primary hover-animation'
                }
                onClick={() => handleItemClick('Following')}
            />
            <input
                type="submit"
                name="Followers"
                value="Followers"
                className={
                    onInputClick !== 'Followers'
                        ? 'neutral-4 font-semibold hover-animation'
                        : 'btn-primary hover-animation'
                }
                onClick={() => handleItemClick('Followers')}
            />
        </div>
    );
    // endregion

    return (
        <div className=" w-screen sm:w-auto">
            <Navbar/>
            <div className="sm:h-screen w-full sm:w-auto overflow-hidden flex flex-1  flex-col items-center">
                <div className="w-full h-1/3 bg-gradient-to-r from-cyan-500 to-primary-1">
                    <img
                        src={banner}
                        alt=""
                        className="sm:w-full w-screen    h-full object-fit sm:object-cover object-center"
                    />
                </div>
                <div className="-mt-60 sm:-mt-40 flex flex-col sm:flex-row  sm:h-fit w-4/5  justify-between items-center sm:items-start">
                    {renderProfileInfos()}
                    <div className="w-full sm:px-16 py-16">
                        {renderFilters()}
                        <div
                            className="w-full flex  sm:flex-row flex-col gap-8 justify-between items-center sm:items-start">
                            <BotCard name="Bot 1" price="50$" author="garen2" followers="45"/>
                            <BotCard name="Bot 2" price="50$" author="garen2" followers="32"/>
                            <BotCard name="Bot 3" price="50$" author="garen2" followers="11"/>
                        </div>
                    </div>

                </div>
                {/* <div */}
                {/*     className="md:absolute md:right-0 md:top-[30vh] h-fit flex gap-4 sm:flex-nowrap sm:flex-nowrap flex-wrap justify-center items-center sm:justify-start sm:items-start"> */}
                {/*     <div */}
                {/*         className="btn-primary-outlined bg-transparent hover-animation text-neutral-8 flex gap-2"> */}
                {/*         <input */}
                {/*             type="submit" */}
                {/*             className="bg-transparent cursor-pointer text-black dark:text-white" */}
                {/*             value="Edit cover photo" */}
                {/*         /> */}
                {/*         <BiImageAlt size={20} className="mt-0.5"/> */}
                {/*     </div> */}
                {/*     <Link */}
                {/*         to="/profile/edit" */}
                {/*         className="btn-primary-outlined h-fit bg-transparent hover-animation text-neutral-8 flex gap-2 " */}
                {/*     > */}
                {/*         <input */}
                {/*             type="submit" */}
                {/*             className="bg-transparent cursor-pointer text-black dark:text-white" */}
                {/*             value="Edit profile" */}
                {/*         /> */}
                {/*         <TbPencil size={20} className="mt-0.5"/> */}
                {/*     </Link> */}
                {/* </div> */}
                <Footer/>
            </div>
        </div>
    );
}

export default Profile;
