import React, { useState } from 'react';
import {
  HiHome,
  HiChatAlt2,
  HiLightningBolt,
  HiPencilAlt,
} from 'react-icons/hi';
import Footer from '../../components/Footer';
import Navbar from '../../components/Navbar';

function FAQ() {
  const [expanded, setExpanded] = useState(false);
  const [expanded2, setExpanded2] = useState(false);
  const [expanded3, setExpanded3] = useState(false);
  const [expanded4, setExpanded4] = useState(false);
  const [expandedcontent, setExpandedContent] = useState(false);
  const [expandedcontent2, setExpandedContent2] = useState(false);
  const [expandedcontent3, setExpandedContent3] = useState(false);
  const [expandedcontent4, setExpandedContent4] = useState(false);
  const [expandedcontent5, setExpandedContent5] = useState(false);
  const [learnmore, setLearnmore] = useState(false);
  // const [learnmore2, setLearnmore2] = useState(false);
  const [learnmore3, setLearnmore3] = useState(false);
  const [learnmore4, setLearnmore4] = useState(false);
  const [learnmore5, setLearnmore5] = useState(false);

  const handleExpand = () => {
    setExpanded(!expanded);
  };

  const handleExpand2 = () => {
    setExpanded2(!expanded2);
  };

  const handleExpand3 = () => {
    setExpanded3(!expanded3);
  };

  const handleExpand4 = () => {
    setExpanded4(!expanded4);
  };

  const handleExpandedContent = () => {
    setExpandedContent(!expandedcontent);
  };

  const handleExpandedContent2 = () => {
    setExpandedContent2(!expandedcontent2);
  };

  const handleExpandedContent3 = () => {
    setExpandedContent3(!expandedcontent3);
  };

  const handleExpandedContent4 = () => {
    setExpandedContent4(!expandedcontent4);
  };

  const handleExpandedContent5 = () => {
    setExpandedContent5(!expandedcontent5);
  };

  const handleLearnmore = () => {
    setLearnmore(!learnmore);
  };

  // const handleLearnmore2 = () => {
  //   setLearnmore2(!learnmore2);
  // };

  const handleLearnmore3 = () => {
    setLearnmore3(!learnmore3);
  };

  const handleLearnmore4 = () => {
    setLearnmore4(!learnmore4);
  };

  const handleLearnmore5 = () => {
    setLearnmore5(!learnmore5);
  };

  return (
    <div className="">
      <Navbar />
      <div className="container md:mt-[128px] mt-14  md:p-4">
        <div className="text-neutral-4 font-bold text-sm mb-[12px]">
          <p className="text-center md:text-start">LEARN HOW TO GET STARTED</p>
        </div>
        <div className="text-neutral-2 font-bold text-[48px] mb-[20px]">
          <p className="text-center md:text-start">Frequently asked questions</p>
        </div>
        <div className="flex items-center text-neutral-2 text-[16px]">
          <p className="text-center md:text-start">Your question isn’t there?</p>
          <a
            href="/support"
            className="ml-1.5 text-primary-1 font-bold text-[16px] text-center md:text-start"
          >
            Try to contact the support
          </a>
        </div>
        <div className="flex md:mt-[80px] my-8 md:flex-row flex-col  md:items:start items-center    ">
          <div className="md:w-1/3 w-2/3 flex md:flex-col items-center justify-center">
            <ul className="md:space-y-[32px] w-2/3 flex md:flex-col flex-wrap flex-1 gap-3 justify-between">
              <li>
                <button type="button"
                  className={expanded ? 'text-neutral-2' : 'text-neutral-4'}
                  onClick={handleExpand}
                >
                  <div className="flex items-center">
                    {expanded ? (
                      <HiHome className="mr-2" />
                    ) : (
                      <HiHome className="mr-2" />
                    )}
                    <p
                      className={
                        expanded
                          ? 'text-neutral-2 font-bold text-[14px]'
                          : 'text-neutral-4 font-bold text-[14px]'
                      }
                    >
                      General
                    </p>
                  </div>
                </button>
              </li>
              <li>
                <button type="button"
                  className={expanded2 ? 'text-neutral-2' : 'text-neutral-4'}
                  onClick={handleExpand2}
                >
                  <div className="flex items-center">
                    {expanded2 ? (
                      <HiChatAlt2 className="mr-2" />
                    ) : (
                      <HiChatAlt2 className="mr-2" />
                    )}
                    <p
                      className={
                        expanded2
                          ? 'text-neutral-2 font-bold text-[14px]'
                          : 'text-neutral-4 font-bold text-[14px]'
                      }
                    >
                      Support
                    </p>
                  </div>
                </button>
              </li>
              <li>
                <button type="button"
                  className={expanded3 ? 'text-neutral-2' : 'text-neutral-4'}
                  onClick={handleExpand3}
                >
                  <div className="flex items-center">
                    {expanded3 ? (
                      <HiLightningBolt className="mr-2" />
                    ) : (
                      <HiLightningBolt className="mr-2" />
                    )}
                    <p
                      className={
                        expanded3
                          ? 'text-neutral-2 font-bold text-[14px]'
                          : 'text-neutral-4 font-bold text-[14px]'
                      }
                    >
                      Hosting
                    </p>
                  </div>
                </button>
              </li>
              <li>
                <button type="button"
                  className={expanded4 ? 'text-neutral-2' : 'text-neutral-4'}
                  onClick={handleExpand4}
                >
                  <div className="flex items-center">
                    {expanded4 ? (
                      <HiPencilAlt className="mr-2" />
                    ) : (
                      <HiPencilAlt className="mr-2" />
                    )}
                    <p
                      className={
                        expanded4
                          ? 'text-neutral-2 font-bold text-[14px]'
                          : 'text-neutral-4 font-bold text-[14px]'
                      }
                    >
                      Product
                    </p>
                  </div>
                </button>
              </li>
            </ul>
          </div>
          <div className="w-2/3">
            <hr className="border-neutral-6 mb-[38px]" />
            <p className="text-neutral-2 font-bold text-[16px]">
              <div className="flex items-center justify-between">
                <p>How does it work</p>
                <button type="button"
                  className="text-neutral-4 text-[16px] font-bold"
                  onClick={handleExpandedContent}
                >
                  {expandedcontent ? 'v' : '^'}
                </button>
              </div>
            </p>
            {expandedcontent && (
              <div className="mt-[38px]">
                <p className="text-neutral-4 text-[16px] mb-[24px]">
                  The concept of Aizy is really simple. You just have to click
                  on a bot after being connected and you can simply watch him
                  trade!
                </p>
                <button type="button"
                  className={
                    learnmore
                      ? null
                      : 'rounded-full border-[2px] border-neutral-6 bg-neutral-8 w-[140px] h-[40px] flex items-center justify-center mt-4 hover-animation'
                  }
                  onClick={handleLearnmore}
                >
                  <p
                    className={
                      learnmore ? null : 'text-neutral-2 text-[16px] font-bold'
                    }
                  >
                    {learnmore ? null : 'Learn more'}
                  </p>
                  {learnmore ? (
                    <p className="text-neutral-4 text-[16px] mb-[24px] text-left">
                      Here&apos;s how the Aizy website works in more detail:
                      <br />
                      <br />
                      1. Rental of Existing Bots: Users can browse the
                      selection of automated trading bots available on the Aizy
                      platform. Each bot is designed to execute specific trading
                      strategies, and users can rent the one that best suits
                      their needs.
                      <br />
                      2. Bot Configuration: Once a user has
                      chosen a bot to rent, they can configure it according to
                      their preferences and risk tolerance. Bot settings may
                      include profit-taking thresholds, stop-loss levels,
                      technical indicators, etc.
                      <br />
                      3. Bot Launch: Once
                      configured, the user can launch the trading bot. The bot
                      will then start executing pre-defined trading strategies
                      based on market conditions.
                      <br />
                      4. Performance
                      Monitoring: Users can monitor their bot&apos;s performance in
                      real-time through dashboards and statistical reports
                      provided by the platform. These reports typically show
                      profits, losses, and other trading-related metrics.
                      <br />
                      5. Custom Bot Creation: Aizy also offers
                      developers the option to create their own custom trading
                      bots. Once created, they can rent out their bots on the
                      platform and receive monthly income from other users who
                      rent them.
                      <br />
                      6. Payment and Billing: Users who rent
                      bots are usually billed periodically, according to the
                      rental terms (monthly, quarterly, etc.). Developers who
                      rent out their bots can also receive regular payments
                      based on the number of users renting their bots.
                      <br />
                      7. Assistance and Support: Aizy generally provides
                      customer support to help users resolve technical issues or
                      answer questions related to the use of bots and the
                      platform.
                    </p>
                  ) : null}
                </button>
              </div>
            )}
            <hr className="border-neutral-6 mt-[38px] mb-[38px]" />
            <p className="text-neutral-2 font-bold text-[16px]">
              <div className="flex items-center justify-between">
                <p>How to start with Aizy</p>
                <button type="button"
                  className="text-neutral-4 text-[16px] font-bold"
                  onClick={handleExpandedContent2}
                >
                  {expandedcontent2 ? 'v' : '^'}
                </button>
              </div>
            </p>
            {expandedcontent2 && (
              <div className="mt-[38px]">
                <p className="text-neutral-4 text-[16px] mb-[24px]">
                  Additional content goes here...
                </p>
                <div className="rounded-full border-[2px] border-neutral-6 bg-neutral-8 w-[140px] h-[40px] flex items-center justify-center mt-4 hover-animation">
                  <button type="button" className="text-neutral-2 text-[16px] font-bold">
                    Learn more
                  </button>
                </div>
              </div>
            )}
            <hr className="border-neutral-6 mt-[38px] mb-[38px]" />
            <p className="text-neutral-2 font-bold text-[16px]">
              <div className="flex items-center justify-between">
                <p>How to create an account on Aizy</p>
                <button type="button"
                  className="text-neutral-4 text-[16px] font-bold"
                  onClick={handleExpandedContent3}
                >
                  {expandedcontent3 ? 'v' : '^'}
                </button>
              </div>
            </p>
            {expandedcontent3 && (
              <div className="mt-[38px]">
                <p className="text-neutral-4 text-[16px] mb-[24px]">
                  To create an account on Aizy, follow these steps:
                  <br />
                </p>
                <button type="button"
                  className={
                    learnmore3
                      ? null
                      : 'rounded-full border-[2px] border-neutral-6 bg-neutral-8 w-[140px] h-[40px] flex items-center justify-center mt-4 hover-animation'
                  }
                  onClick={handleLearnmore3}
                >
                  <p
                    className={
                      learnmore3 ? null : 'text-neutral-2 text-[16px] font-bold'
                    }
                  >
                    {learnmore3 ? null : 'Learn more'}
                  </p>
                  {learnmore3 ? (
                    <p className="text-neutral-4 text-[16px] mb-[24px] text-left">
                      1. Go to the
                      {' '}
                      <a href="/sign-up" className="text-primary-1 font-bold">
                        sign-up page
                      </a>
                      {' '}
                      on the Aizy website.
                      <br />
                      2. Fill in the required
                      fields for your username, email address, and password.
                      <br />
                      3. Click on the &quot;Create my page&quot; button to create
                      your account.
                      <br />
                      <br />
                      Once these steps are completed, your Aizy account
                      will be created, and you can start using the website&apos;s
                      features..
                    </p>
                  ) : null}
                </button>
              </div>
            )}
            <hr className="border-neutral-6 mt-[38px] mb-[38px]" />
            <p className="text-neutral-2 font-bold text-[16px]">
              <div className="flex items-center justify-between">
                <p>How to upload a bot</p>
                <button type="button"
                  className="text-neutral-4 text-[16px] font-bold"
                  onClick={handleExpandedContent4}
                >
                  {expandedcontent4 ? 'v' : '^'}
                </button>
              </div>
            </p>
            {expandedcontent4 && (
              <div className="mt-[38px]">
                <p className="text-neutral-4 text-[16px] mb-[24px]">
                  To upload a bot on the Aizy website, you first need to obtain
                  the developer status. Once you have the developer status,
                  follow these steps:
                  <br />
                </p>
                <button type="button"
                  className={
                    learnmore4
                      ? null
                      : 'rounded-full border-[2px] border-neutral-6 bg-neutral-8 w-[140px] h-[40px] flex items-center justify-center mt-4 hover-animation'
                  }
                  onClick={handleLearnmore4}
                >
                  <p
                    className={
                      learnmore4 ? null : 'text-neutral-2 text-[16px] font-bold'
                    }
                  >
                    {learnmore4 ? null : 'Learn more'}
                  </p>
                  {learnmore4 ? (
                    <p className="text-neutral-4 text-[16px] mb-[24px] text-left">
                      1. Go to the
                      {' '}
                      <a href="/upload" className="text-primary-1 font-bold">
                        upload page
                      </a>
                      {' '}
                      on the Aizy website.
                      <br />
                      2. Select the file containing
                      your bot from your computer.
                      <br />
                      3. Answer the
                      questions asking for the name you want to give to your
                      bot, the price you have set, and the currency you have
                      chosen.
                      <br />
                      4. Click the &quot;Upload&quot; button to upload
                      your bot to Aizy.
                      <br />
                      <br />
                      Once the upload process is complete, your bot
                      will be available on Aizy for use by the website users.
                    </p>
                  ) : null}
                </button>
              </div>
            )}
            <hr className="border-neutral-6 mt-[38px] mb-[38px]" />
            <p className="text-neutral-2 font-bold text-[16px]">
              <div className="flex items-center justify-between">
                <p>How to be a developer on Aizy</p>
                <button type="button"
                  className="text-neutral-4 text-[16px] font-bold"
                  onClick={handleExpandedContent5}
                >
                  {expandedcontent5 ? 'v' : '^'}
                </button>
              </div>
            </p>
            {expandedcontent5 && (
              <div className="mt-[38px]">
                <p className="text-neutral-4 text-[16px] mb-[24px]">
                  To become a developer on Aizy, follow these steps:
                  <br />
                </p>
                <button type="button"
                  className={
                    learnmore5
                      ? null
                      : 'rounded-full border-[2px] border-neutral-6 bg-neutral-8 w-[140px] h-[40px] flex items-center justify-center mt-4 hover-animation'
                  }
                  onClick={handleLearnmore5}
                >
                  <p
                    className={
                      learnmore5 ? null : 'text-neutral-2 text-[16px] font-bold'
                    }
                  >
                    {learnmore5 ? null : 'Learn more'}
                  </p>
                  {learnmore5 ? (
                    <p className="text-neutral-4 text-[16px] mb-[24px] text-left">
                      1. Create an account on the Aizy website if you don&quot;t have
                      one already.
                      <br />
                      2. Go to the
                      {' '}
                      <a href="/profile" className="text-primary-1 font-bold">
                        profile page
                      </a>
                      {' '}
                      on the Aizy website.
                      <br />
                      3. Click on the &quot;Developer
                      mode&quot; option to enable developer mode for your profile.
                      <br />
                      4. Upload a valid identification document as
                      requested.
                      <br />
                      5. Your profile will be verified by the
                      Aizy team, and once approved, you will be granted
                      developer status.
                      <br />
                      <br />
                      Please note that the Aizy team will review your
                      profile and documentation to ensure eligibility for the
                      developer status.
                    </p>
                  ) : null}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default FAQ;
